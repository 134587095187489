import React from 'react';
import { Button } from '../FormComponents/Buttons/Button';
import './ErrorMessage.scss';

type ErrorMessageProps = {
  message: string;
  closeModalCallback?: () => void;
};

const ErrorMessage = ({ message, closeModalCallback }: ErrorMessageProps) => {
  const handleCloseModal = () => {
    closeModalCallback ? closeModalCallback() : window.location.reload();
  };

  return (
    <div className="error-message">
      <div className="error-message-container">
        <p className="error-message-text">{message}</p>
        <div className="error-message-button-wrapper">
          <Button
            type="button"
            translationKey="error-messages.ok-button"
            variant="outlined"
            colour="black"
            onClick={() => handleCloseModal()}
            additionalClassName="mr-3 py-2 px-5"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
