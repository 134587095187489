import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useGet, useMutate } from 'restful-react';
import { useNavigate } from 'react-router';
import Switch from 'react-switch';

import './PhysioMyDetails.scss';

import { PhysioDetails } from '../../types/PhysioDetails';
import { Button } from '../../components/FormComponents/Buttons/Button';
import { TextInput } from '../../components/FormComponents/TextInput/TextInput';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { PageLayout } from '../../layout/PageLayout';

const PhysioMyDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShareDetailsSwitchOn, setIsShareDetailsSwitchOn] = useState(false);

  const onSuccess = () => {
    navigate('/home');
  };

  const { data: physioDetails, error: errorGettingUserContactDetails } = useGet({
    path: `user/contact-details`,
    resolve: (physio: PhysioDetails) => {
      reset(physio);
      return physio;
    },
  });

  const defaultValues: PhysioDetails = {
    contactName: physioDetails?.contactName || '',
    contactEmail: physioDetails?.contactEmail || '',
    contactPhoneNumber: physioDetails?.contactPhoneNumber || '',
    shareDetailsWithPatients: isShareDetailsSwitchOn,
  };

  const { control, handleSubmit, reset, formState } = useForm<PhysioDetails>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    mutate: updatePhysioDetails,
    loading: awaitingUpdateApiCall,
    error: errorUpdatingUserContractDetails,
  } = useMutate<PhysioDetails>({
    verb: 'PUT',
    path: '/user/contact-details',
    onMutate: onSuccess,
  });

  const onSubmit = (data: PhysioDetails) => {
    updatePhysioDetails(data);
  };

  const handleBackBtnClick = () => {
    navigate('/home');
  };

  const isValidEmailAddress = (email: string) => {
    if (email === '') {
      return true;
    }
    // eslint-disable-next-line
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  if (errorGettingUserContactDetails || errorUpdatingUserContractDetails) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <PageLayout
      headerTitle={t(['my-details.title'])}
      headerBackBtnClick={handleBackBtnClick}
      headerBackBtnText="navbar.back-clients"
    >
      <div className="columns">
        <div className="column is-7">
          <form className="is-flex is-flex-direction-column" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="contactName"
              control={control}
              rules={{
                required: false,
                maxLength: 100,
              }}
              render={({ field }) => (
                <TextInput
                  placeholder={t(['forms.placeholder'])}
                  label={t(['forms.physio-details.name'])}
                  value={field.value}
                  onChange={field.onChange}
                  errorType={formState.errors.contactName}
                  errorMessageKey="error"
                />
              )}
            />
            <Controller
              name="contactEmail"
              control={control}
              rules={{
                required: false,
                maxLength: 100,
                validate: (value) => isValidEmailAddress(value),
              }}
              render={({ field }) => (
                <TextInput
                  placeholder={t(['forms.placeholder'])}
                  label={t(['forms.physio-details.email'])}
                  value={field.value}
                  onChange={field.onChange}
                  errorType={formState.errors.contactEmail}
                  errorMessageKey="forms.physio-details.invalid-email"
                  validationPosition="after"
                />
              )}
            />
            <Controller
              name="contactPhoneNumber"
              control={control}
              rules={{ required: false, maxLength: 100 }}
              render={({ field }) => (
                <TextInput
                  placeholder={t(['forms.placeholder'])}
                  label={t(['forms.physio-details.phone'])}
                  value={field.value}
                  onChange={field.onChange}
                  errorType={formState.errors.contactPhoneNumber}
                  errorMessageKey="error"
                />
              )}
            />
            <label className="physio-my-details__share-block">
              {t(['forms.physio-details.share'])}
            </label>
            <div className="is-flex is-align-items-center mt-4 mb-5">
              <Controller
                name="shareDetailsWithPatients"
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      setIsShareDetailsSwitchOn(e);
                    }}
                    onColor="#EC9A1E"
                  />
                )}
              />
            </div>
            <div className="buttons mt-4 is-align-self-flex-end">
              <Button
                disabled={!formState.isValid || awaitingUpdateApiCall}
                type="submit"
                translationKey="forms.physio-details.save"
                variant="outlined"
                colour="black"
                additionalClassName="py-2 px-5"
              ></Button>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default PhysioMyDetails;
