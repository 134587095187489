import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from 'restful-react';

import { ExerciseType, MovementType } from '../../types/ExerciseDetails';
import { OkResponse } from '../../types/responses';

import './Exercises.scss';

import Header from '../../components/Header/Header';
import { useParams, useNavigate } from 'react-router';
import { Button } from '../../components/FormComponents/Buttons/Button';
import ExercisesByMovementTypeList from './ExercisesByMovementTypeList';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { NavSidebar, NavSidebarItem } from '../../components/NavSidebar/NavSidebar';
import { useLanguagePreference } from '../../hooks/useLanguagePreference';

type RouterParams = {
  patientId: string;
  programmeId: string;
};

const compare = (a: ExerciseType, b: ExerciseType) => {
  return a.movementType.name.toLowerCase().localeCompare(b.movementType.name.toLowerCase());
};

const ExercisesList = () => {
  const { t } = useTranslation();
  const { programmeId } = useParams<RouterParams>();
  const navigate = useNavigate();
  const [activeMovementTypeId, setActiveMovementTypeId] = useState('');
  const [sortedMovementTypes, setSortedMovementTypes] = useState<MovementType[] | null>(null);
  const [navItems, setNavItems] = useState<NavSidebarItem[]>([]);
  const { patientId } = useParams<RouterParams>();
  const { context } = useLanguagePreference();

  const {
    data: exercises,
    loading: exerciseLoading,
    error: errorGettingExercise,
    refetch: refetchExercises,
  } = useGet({
    path: `/exercise`,
    lazy: true,
    resolve: (data: OkResponse<ExerciseType>) => {
      const res = data.responses;
      res.sort(compare);
      return res;
    },
  });

  const {
    data: movementTypes,
    loading: movementLoading,
    error: errorGettingMovementType,
    refetch: refetchMovements,
  } = useGet({
    path: `/movementType`,
    lazy: true,
    resolve: (data: OkResponse<MovementType>) => {
      let res = data.responses;
      res = res.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      return res;
    },
  });

  useEffect(() => {
    refetchExercises();
    refetchMovements();
  }, [context.userLanguagePreference, refetchExercises, refetchMovements]);

  useEffect(() => {
    if (exerciseLoading || movementLoading || !exercises || !movementTypes) {
      return;
    }
    // need to make introductions the first in the movement type list based on its id
    // from one of the intro exercises
    const introOnly = exercises.filter((x) => x.introductory === true);
    if (introOnly.length === 0) {
      return;
    }
    const introMovementTypeId = introOnly[0].movementTypeId;
    const introductionsIndex = movementTypes.findIndex(
      (el) => el.movementTypeId === introMovementTypeId,
    );
    const introductionEl = movementTypes[introductionsIndex];
    const sortedMoves = [...movementTypes];
    sortedMoves.splice(introductionsIndex, 1);
    sortedMoves.unshift(introductionEl);
    setSortedMovementTypes(sortedMoves);
    setActiveMovementTypeId(sortedMoves[0].movementTypeId);
    const asItems = new Array<NavSidebarItem>();
    sortedMoves.forEach((item) => {
      asItems.push({
        id: item.movementTypeId,
        name: item.name,
      });
    });
    setNavItems(asItems);
  }, [movementTypes, exercises, exerciseLoading, movementLoading]);

  const returnToPatientProgrammeEditPage = () => {
    navigate(`/patient/${patientId}?editing=true`);
  };

  const scrollTo = (elId: string) => {
    const el = document.getElementById(elId);
    el?.scrollIntoView({
      behavior: 'smooth',
    });
    return;
  };

  if (errorGettingExercise || errorGettingMovementType) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (exerciseLoading || movementLoading) {
    return <LoadingSpinner isLocal={false} />;
  }

  return (
    <>
      <Header
        title={
          programmeId ? t(['exercises.page-title-creating']) : t(['exercises.page-title-list'])
        }
      />
      <div className="container is-widescreen content-container">
        <div className="columns">
          <div className="column is-3">
            {navItems.length > 0 && (
              <NavSidebar
                items={navItems}
                activeItemId={activeMovementTypeId}
                setActiveItem={setActiveMovementTypeId}
                handleOnClick={scrollTo}
              />
            )}
          </div>
          <div className="column is-8">
            {programmeId && (
              <div className="plan-exercises__header">
                <h1>{t(['exercises.page-subtitle-list'])}</h1>
                <Button
                  onClick={() => returnToPatientProgrammeEditPage()}
                  translationKey="exercises.done"
                  variant="filled"
                  colour="black"
                  type="button"
                />
              </div>
            )}
            {sortedMovementTypes &&
              sortedMovementTypes.map((movementType) => {
                const typeExercises = exercises?.filter(
                  (ex) => ex.movementType.movementTypeId === movementType.movementTypeId,
                );
                return (
                  <ExercisesByMovementTypeList
                    key={movementType.movementTypeId}
                    programmeId={programmeId}
                    movementType={movementType}
                    typeExercises={typeExercises}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExercisesList;
