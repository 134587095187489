import React from 'react';
import './NavSidebar.scss';

export interface NavSidebarItem {
  id: string;
  name: string;
}

interface NavSidebarProps {
  items: NavSidebarItem[];
  activeItemId: string;
  setActiveItem: (id: string) => void;
  handleOnClick?: (name: string) => void;
}

export const NavSidebar = ({
  items,
  activeItemId: activeItem,
  setActiveItem,
  handleOnClick = () => {},
}: NavSidebarProps) => {
  const handleClick = (item: NavSidebarItem) => {
    handleOnClick(item.name);
    setActiveItem(items[items.indexOf(item)].id);
  };

  return (
    <ul className="sidebar">
      {items &&
        items.map((item) => {
          return (
            <li
              className={`sidebar__item ${item.id === activeItem ? 'is-active' : ''}`}
              key={item.id}
              onClick={() => handleClick(item)}
            >
              {item.name}
            </li>
          );
        })}
    </ul>
  );
};
