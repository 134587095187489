import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from 'restful-react';

import PatientCard from '../../components/PatientCard/PatientCard';
import './Home.scss';
import PersonIcon from '../../images/icon-person.svg';
import EnvelopeIcon from '../../images/icon-envelope.svg';

import { OkResponse, PatientResponse } from '../../types/responses';
import { DeletePatientModal } from '../../components/Modals/DeletePatientModal/DeletePatientModal';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { Button } from '../../components/FormComponents/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { UserInviteModal } from '../../components/Modals/UserInviteModal/UserInviteModal';
import { INVITE_ID_KEY } from '../../constants';
import { PageLayout } from '../../layout/PageLayout';
import { LANG_TO_UPDATE_KEY, useLanguagePreference } from '../../hooks/useLanguagePreference';

const Home = () => {
  const { t } = useTranslation();
  const [isDeletePatientModalOpen, setIsDeletePatientModalOpen] = useState(false);
  const [patientId, setPatientId] = useState('');
  const navigate = useNavigate();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { setDatabaseLanguage } = useLanguagePreference();
  const inviteCode = localStorage.getItem(INVITE_ID_KEY);

  useEffect(() => {
    if (inviteCode) {
      setIsInviteModalOpen(true);
    }
  }, [inviteCode]);

  useEffect(() => {
    const setLanguage = async () => {
      await setDatabaseLanguage();
    };
    if (localStorage.getItem(LANG_TO_UPDATE_KEY) === 'true') {
      setLanguage();
      localStorage.removeItem(LANG_TO_UPDATE_KEY);
    }
  }, [setDatabaseLanguage]);

  const {
    data,
    loading: awaitingGetApiResults,
    error: errorGetingApiResults,
    refetch,
  } = useGet({
    path: `/patient`,
    resolve: (data: OkResponse<PatientResponse>) => {
      return data.responses.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
    },
  });

  const handleOpenDeletePatientModal = (patientId: string) => {
    setPatientId(patientId);
    setIsDeletePatientModalOpen(true);
  };

  const handleCloseUserInviteModal = () => {
    refetch();
    setIsInviteModalOpen(false);
  };

  if (errorGetingApiResults) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (isInviteModalOpen) {
    return (
      <UserInviteModal
        isOpen={isInviteModalOpen}
        handleCloseModal={handleCloseUserInviteModal}
        inviteCode={inviteCode ? inviteCode : ''}
      />
    );
  }

  if (isDeletePatientModalOpen) {
    return (
      <DeletePatientModal
        isOpen={isDeletePatientModalOpen}
        handleCloseModal={() => setIsDeletePatientModalOpen(false)}
        patientId={patientId}
      />
    );
  }

  // TODO don't think the invite token button is right yet and only shows 2 card cols
  return (
    <PageLayout showHero={true} showHeader={false}>
      <div className="page-title with-buttons">
        <h1>{t('home.title')}</h1>
        <div className="home-button-wrapper">
          <Button
            translationKey={'home.add-patient'}
            variant="filled"
            colour="orange"
            type="button"
            onClick={() => navigate('/patient/add')}
            icon={PersonIcon}
            additionalClassName={'home-add-patient-button'}
          ></Button>
          <Button
            translationKey={'home.enter-code'}
            variant="outlined"
            colour="orange"
            type="button"
            icon={EnvelopeIcon}
            onClick={() => setIsInviteModalOpen(true)}
            additionalClassName={'home-outlined-button'}
          ></Button>
        </div>
      </div>
      <div className="patients-list">
        {awaitingGetApiResults ? (
          <div className="home-loading-spinner-container">
            <LoadingSpinner isLocal={true} />
          </div>
        ) : null}
        {data &&
          data.map((patient) => (
            <PatientCard
              key={data.indexOf(patient)}
              href={`/patient/${patient.id}`}
              patientId={patient.id}
              name={patient.name}
              age={patient.age}
              callbackHandleOpenDeletePatientModal={() => handleOpenDeletePatientModal(patient.id)}
            />
          ))}
      </div>
    </PageLayout>
  );
};

export default Home;
