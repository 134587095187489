import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PatientCard.scss';
import { PatientCardDetails } from '../../types/PatientDetails';
import DeleteTrashCan from '../../images/icon-dark-delete-trash-can.svg';
import { useTranslation } from 'react-i18next';

const PatientCard = ({
  name,
  age,
  href,
  patientId,
  addClient,
  callbackHandleOpenDeletePatientModal,
}: PatientCardDetails) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');

  const handleOpenDeletePatientModal = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();
    callbackHandleOpenDeletePatientModal(patientId);
  };

  const handleOpen = (event: any) => {
    navigate(href);
  };

  return (
    <div onClick={handleOpen} className={`patient-card ${addClient ? 'is-add' : ''}`}>
      <div className="patient-card__image">
        <div className="patient-card__initials-container">
          <p className="patient-card__initials">{initials}</p>
        </div>
      </div>
      <div className="patient-card__details">
        <p className="patient-card__details__name">{name}</p>
        <p className="patient-card__details__age">{age && `${t('home.age')}: ${age}`}</p>
      </div>
      {!addClient && (
        <div className="patient-card__delete">
          <img
            className="patient-card__delete__image"
            src={DeleteTrashCan}
            alt="delete"
            onClick={handleOpenDeletePatientModal}
          />
        </div>
      )}
    </div>
  );
};

export default PatientCard;
