import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExerciseForm from '../../components/ExerciseForm/ExerciseForm';
import { useLocation } from 'react-router-dom';
import { PageLayout } from '../../layout/PageLayout';

type ExerciseProps = {
  isProgramme: boolean;
  newExercise?: boolean;
};

const Exercise = ({ isProgramme, newExercise }: ExerciseProps) => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const isReadOnlyQueryString = new URLSearchParams(search).get('readonly');
  const [isReadOnly] = useState(isReadOnlyQueryString === 'true' ? true : false);

  return (
    <PageLayout
      headerTitle={
        isProgramme && !isReadOnly
          ? t(['forms.exercise.page-title-edit'])
          : t(['forms.exercise.page-title-detail'])
      }
    >
      <ExerciseForm newExercise={newExercise} />
    </PageLayout>
  );
};

export default Exercise;
