import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExerciseCard from '../../components/ExerciseCard/ExerciseCard';
import { ExerciseType, MovementType } from '../../types/ExerciseDetails';
import PlaceholderImg from '../../images/ex_placeholder.jpg';

type Props = {
  movementType: MovementType;
  typeExercises: any[] | undefined;
  programmeId?: string;
};

const ExercisesByMovementTypeList = ({ movementType, typeExercises, programmeId }: Props) => {
  const navigate = useNavigate();

  const handleCardClick = (exercise: ExerciseType) => {
    goToExercise(exercise);
  };
  const goToExercise = (exercise: ExerciseType) => {
    if (programmeId) {
      navigate(`/programme/${programmeId}/exercise/add/${exercise.exerciseId}`);
    } else {
      navigate(`/exercise/${exercise.exerciseId}`);
    }
  };

  return (
    <div id={movementType.name}>
      <h1 className="section-title">{movementType.name}</h1>
      <div className="exercises-list">
        {typeExercises?.map((ex) => (
          <ExerciseCard
            onClick={() => handleCardClick(ex)}
            key={ex.exerciseId}
            id={ex.exerciseId}
            title={ex.title}
            img={ex.urlImage ? ex.urlImage : PlaceholderImg}
            description={ex.shortDescription}
            isEditingPlan={true}
          />
        ))}
      </div>
    </div>
  );
};

export default ExercisesByMovementTypeList;
