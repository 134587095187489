import notoSans from './NotoSansJP-Regular.ttf';
import openSans from './OpenSans-Regular.ttf';

interface PdfFonts {
  [key: string]: {
    family: string;
    src: string;
  };
}

export const fonts: PdfFonts = {
  default: {
    family: 'Open Sans',
    src: openSans,
  },
  'ja-JP': {
    family: 'Noto Sans',
    src: notoSans,
  },
  'zh-TW': {
    family: 'Noto Sans',
    src: notoSans,
  },
};

export default fonts;
