import React from 'react';
import './VerifyEmailMessage.scss';
import Logo from '../../images/logo-flexterity.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../FormComponents/Buttons/Button';
import { useTranslation } from 'react-i18next';

const VerifyEmailMessage = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    <div className="verify-email-message">
      <div className="verify-email-message-header">
        <img className="verify-email-message-logo" src={Logo} alt="Flexterity Logo" />
      </div>
      <div className="verify-email-message-text-wrapper">
        <p className="verify-email-message-text">{t('require-verification.title')}</p>
        <p className="verify-email-message-text">{t('require-verification.instructions')}</p>
      </div>
      <Button
        type="button"
        variant="outlined"
        colour="black"
        onClick={() => loginWithRedirect()}
        translationKey="require-verification.continue"
        additionalClassName="py-1"
      />
    </div>
  );
};

export default VerifyEmailMessage;
