import React, { useEffect, useState } from 'react';
import './ReviewDate.scss';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useMutate } from 'restful-react';
import { getLocalDateFromUtc, getUtcFromLocalDate } from '../../../helpers/dateFormatHelper';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { Button } from '../../FormComponents/Buttons/Button';
import { useLanguagePreference } from '../../../hooks/useLanguagePreference';

type Props = {
  draftProgramme: ProgrammeDetails;
  handleBackButtonClick: () => void;
  handleContinueButtonClick: () => void;
};

const ReviewDate = ({
  draftProgramme,
  handleBackButtonClick,
  handleContinueButtonClick,
}: Props) => {
  const { t } = useTranslation();
  const [selectedReviewDate, setSelectedReviewDate] = useState('');
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();

  const { mutate: savePlanReviewDate, error: errorUpdatingPlan } = useMutate({
    verb: 'PUT',
    path: `/programme`,
  });

  const onDateChange = (value: any) => {
    setSelectedReviewDate(value);
    const utcDateFromLocal = getUtcFromLocalDate(value);
    const updateProgrammePayload = {
      id: draftProgramme?.programmeId,
      ReviewDateUtc: utcDateFromLocal,
    };
    if (draftProgramme && draftProgramme.reviewDateUtc) {
      draftProgramme.reviewDateUtc = utcDateFromLocal;
    }
    savePlanReviewDate(updateProgrammePayload);
  };

  useEffect(() => {
    setSelectedReviewDate(getLocalDateFromUtc(draftProgramme.reviewDateUtc));
  }, [draftProgramme.reviewDateUtc]);

  if (errorUpdatingPlan) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  const dateFormatter = (locale: string, date: Date): string => {
    // this may seem redundant but we need to do this because for japanese the
    // default behviour shows the character for day after the number and they do not want that
    return date.getDate().toString();
  };

  return (
    <>
      <div className="review-date__overview">
        <div className="review-date__overview-info">
          <h1 className="review-date__overview-title">
            {t('forms.patient.programme-actions.review-date-heading')}
          </h1>
          <p>{t('forms.patient.programme-actions.review-date-description')}</p>
          <br />
          <p>{t('forms.patient.programme-actions.review-date-instructions')}</p>
        </div>
      </div>
      <div className="review-date__date-picker">
        <Calendar
          onChange={onDateChange}
          minDate={new Date()}
          minDetail="year"
          value={selectedReviewDate ? new Date(selectedReviewDate) : null}
          locale={userLanguagePreference || 'en-GB'}
          formatDay={dateFormatter}
        />
      </div>
      <div className="review-date__button-wrapper">
        <Button
          type="button"
          translationKey="forms.patient.programme-actions.back-button"
          variant="outlined"
          colour="black"
          onClick={handleBackButtonClick}
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
        ></Button>
        <Button
          type="button"
          translationKey="forms.patient.programme-actions.continue-button"
          variant="outlined"
          colour="black"
          onClick={handleContinueButtonClick}
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
        ></Button>
      </div>
    </>
  );
};

export default ReviewDate;
