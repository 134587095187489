import Markdown from 'markdown-to-jsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLanguagePreference } from '../../hooks/useLanguagePreference';
import { PageLayout } from '../../layout/PageLayout';
import './HelpPages.scss';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();
  const [privacyDocument, setPrivacyDocument] = React.useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userLanguagePreference !== null) {
      import(`./privacy-markdown/privacy_${userLanguagePreference}.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => setPrivacyDocument(res))
            .catch(() => navigate('/page-not-found'));
        })
        .catch(() => navigate('/page-not-found'));
    }
  }, [userLanguagePreference, navigate]);

  return (
    <div className="help-pages">
      <PageLayout headerTitle={t('help-pages.privacy-title')}>
        <Markdown>{privacyDocument}</Markdown>
      </PageLayout>
    </div>
  );
};

export default PrivacyPolicy;
