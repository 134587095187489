import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useGet, useMutate } from 'restful-react';
import Switch from 'react-switch';
import Select from 'react-select';
import ReactPlayer from 'react-player';

import {
  CreateStepRequest,
  ExerciseFormType,
  ExerciseType,
  IntersectionExerciseType,
  ProgrammeExerciseType,
  SelectOptionType,
  SelectOptionTypeString,
  StepPayload,
} from '../../types/ExerciseDetails';
import {
  StepTypeResponse,
  EditExercisePayload,
  EditProgrammeExercise,
  OkResponse,
  AddExerciseToProgrammePayload,
} from '../../types/responses';
import { useTranslation } from 'react-i18next';
import { Button } from '../FormComponents/Buttons/Button';
import { ControlledLabelNoInput } from './ControlledLabelNoInput';
import { StepsList } from './StepsList';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useLanguagePreference } from '../../hooks/useLanguagePreference';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';

type Props = {
  newExercise?: boolean;
};

type RouterParams = {
  programmeId: string;
  exerciseId: string;
};

let repsOptions: SelectOptionType[] = [];
for (let i = 1; i <= 10; i++) {
  const option: SelectOptionType = { value: i, label: i };
  repsOptions.push(option);
}

let actionDurationOptions: SelectOptionType[] = [];
const actionSecondsRange = {
  from: 5,
  to: 60,
  increment: 5,
};
for (
  let i = actionSecondsRange.from;
  i <= actionSecondsRange.to;
  i = i + actionSecondsRange.increment
) {
  const option: SelectOptionType = { value: i, label: i };
  actionDurationOptions.push(option);
}

// TODO feature flag (kinda!) for disabling time limited functionality for now
const DISABLE_TIME_LIMITED = true;

const ExerciseForm = ({ newExercise }: Props) => {
  const { t } = useTranslation();
  const { raiseEvent } = useGoogleAnalytics();
  const { programmeId, exerciseId } = useParams<RouterParams>();
  const navigate = useNavigate();

  const search = useLocation().search;
  const isReadOnlyQueryString = new URLSearchParams(search).get('readonly');
  const [isReadOnly] = useState(isReadOnlyQueryString === 'true' ? true : false);

  const [exerciseDetails, setExerciseDetails] = useState<IntersectionExerciseType>();
  // TODO see P0936-335 re timing of saving isTimeLImited vs Step data to database. Can maybe remove this
  // spearate state once that is done and just use the property on exerciseDetails snce then the two things
  // should be in sync
  const [isTimeLimitedSwitchOn, setIsTimeLimitedSwitchOn] = useState(false);
  const [availableSelectStepOptions, setAvailableSelectStepOptions] = useState<
    SelectOptionTypeString[]
  >([
    {
      label: '',
      value: '',
      key: '',
    },
  ]);
  const { context } = useLanguagePreference();

  const { control, formState, reset, getValues, handleSubmit, setValue } =
    useForm<ExerciseFormType>({
      mode: 'onChange',
      defaultValues: {
        exerciseId: '',
        title: '',
        shortDescription: '',
        longDescription: '',
        sequence: 0,
        disclaimer: '',
        actionName: availableSelectStepOptions[0],
        actionDuration: actionDurationOptions[0],
        objective: '',
        timeLimited: isTimeLimitedSwitchOn,
        introductory: false,
        movementType: {
          name: '',
        },
        reps: repsOptions[0],
      },
    });

  const onSuccess = (res: unknown) => {
    navigate(-1);
    return res;
  };

  const {
    data: exercise,
    error: errorGettingExerciseForm,
    refetch: refetchExerciseData,
  } = useGet({
    path:
      programmeId && !newExercise
        ? `/programme/${programmeId}/exercise/${exerciseId}`
        : `/exercise/${exerciseId}`,
    lazy: true,
    resolve: (exercise) => {
      setExerciseDetails(exercise);
      setIsTimeLimitedSwitchOn(exercise.timeLimited);
      reset(exercise);
      // TODO this is part of the unused time litd stuff - can remove in future
      // setValue('actionSteps', {
      //   value: exercise.movementType.name,
      //   label: exercise.movementType.name,
      // });
      setValue('reps', { value: exercise.reps, label: exercise.reps });
      return exercise;
    },
  });

  const { error: errorGettingAvailableSteps, refetch: refetchSteps } = useGet({
    path: `/Step/StepTypes`,
    lazy: true,
    resolve: (res: OkResponse<StepTypeResponse>) => {
      const avSteps = res.responses;
      const stepsOptions = avSteps.map((step) => ({
        value: step.name,
        label: step.name,
        key: step.stepTypeId,
      }));
      setAvailableSelectStepOptions(stepsOptions);
      return avSteps;
    },
  });

  useEffect(() => {
    refetchExerciseData();
    refetchSteps();
  }, [context.userLanguagePreference, refetchExerciseData, refetchSteps]);

  const {
    mutate: saveNewStep,
    loading: awaitingSaveNewStepApi,
    error: errorSavingProgrammeStep,
  } = useMutate<string>({
    verb: 'POST',
    path: '/programme/step',
  });

  const {
    mutate: updateProgrammeExercise,
    loading: awaitingUpdatingProgrammeExecApi,
    error: errorUpdatingExercise,
  } = useMutate<EditExercisePayload>({
    verb: 'PUT',
    path: 'programme/exercise',
    onMutate: onSuccess,
  });

  const addStep = (name: SelectOptionTypeString, duration: SelectOptionType) => {
    if (name !== undefined && duration !== undefined && exerciseDetails) {
      const steps = exerciseDetails.steps;
      const newStep: CreateStepRequest = {
        stepTypeId: availableSelectStepOptions.filter((x) => x.value === name.value)[0].key,
        durationSeconds: duration.value,
        sequence: steps?.length ? steps.length + 1 : 1,
        programmeExerciseId: exerciseDetails.programmeExerciseId,
      };
      saveNewStep(newStep).then((res) => {
        const newStepState: StepPayload = { ...newStep, name: name.value, stepId: res };
        setExerciseDetails({
          ...exerciseDetails,
          steps: [...exerciseDetails.steps, newStepState],
        });
      });
    }
  };

  const onProgrammeExerciseSubmit = (data: ProgrammeExerciseType) => {
    if (!exerciseDetails) {
      return;
    }
    let reps;
    if (data.reps) {
      const repsOption = data.reps as SelectOptionType;
      reps = repsOption.value;
    }
    const updateProgrammeExerciseDetails: EditProgrammeExercise = {
      programmeExerciseId: exerciseDetails.programmeExerciseId,
      exerciseId: exerciseDetails.baseExerciseId,
      programmeId: exerciseDetails.programmeId,
      sequence: exerciseDetails.sequence,
      timeLimited: isTimeLimitedSwitchOn,
      reps: reps || 0,
    };
    updateProgrammeExercise(updateProgrammeExerciseDetails);
  };

  const removeStep = (stepId: string) => {
    if (!exerciseDetails || !exerciseDetails.steps) {
      return;
    }
    deleteStep(stepId).then((res) => {
      const newSteps = exerciseDetails.steps.filter((step) => step.stepId !== stepId);
      setExerciseDetails({
        ...exerciseDetails,
        steps: newSteps,
      });
    });
  };

  const { mutate: deleteStep, error: errorDeletingStep } = useMutate({
    verb: 'DELETE',
    path: `/step`,
  });

  const { mutate: deleteExerciseFromPlan, error: errorDeletingExerciseFromPlan } = useMutate({
    verb: 'DELETE',
    path: `/programme/removeexercise/${programmeId}`,
    onMutate: onSuccess,
  });

  const handleDeleteExercise = (e: React.MouseEvent<HTMLElement>, id: string | undefined) => {
    e.stopPropagation();
    if (!id) {
      return;
    }
    deleteExerciseFromPlan(id);
  };

  const addExerciseToProgramme = (e: React.MouseEvent<HTMLElement>, exercise: ExerciseType) => {
    e.stopPropagation();
    if (programmeId) {
      const copyExercise: AddExerciseToProgrammePayload = {
        exerciseId: exercise.exerciseId,
        programmeId: programmeId,
      };
      saveNewProgrammeExercise(copyExercise).then((res) => {
        raiseEvent(exercise.title, 'Exercise added to plan');
        navigate(-1);
      });
    }
  };

  const { mutate: saveNewProgrammeExercise, error: errorPostingProgrammeExercise } =
    useMutate<AddExerciseToProgrammePayload>({
      verb: 'POST',
      path: '/Programme/exercise',
    });

  if (
    errorPostingProgrammeExercise ||
    errorGettingExerciseForm ||
    errorGettingAvailableSteps ||
    errorSavingProgrammeStep ||
    errorUpdatingExercise ||
    errorDeletingStep ||
    errorDeletingExerciseFromPlan
  ) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <div className="columns">
      <div className="column is-7">
        <form
          className="is-flex is-flex-direction-column"
          onSubmit={handleSubmit(onProgrammeExerciseSubmit)}
        >
          <ControlledLabelNoInput
            name="title"
            control={control}
            label={t(['forms.exercise.title'])}
          />
          <ControlledLabelNoInput
            name="longDescription"
            control={control}
            label={t(['forms.exercise.longDescription'])}
          />
          {exerciseDetails?.shortDescription && (
            <ControlledLabelNoInput
              name="shortDescription"
              control={control}
              label={t(['forms.exercise.shortDescription'])}
            />
          )}
          <ControlledLabelNoInput
            name="disclaimer"
            control={control}
            label={t(['forms.exercise.disclaimer'])}
          />
          {!exerciseDetails?.introductory && programmeId && (
            <>
              <ControlledLabelNoInput
                name="movementType.name"
                label={t(['forms.exercise.movementType'])}
                control={control}
              />
              {!DISABLE_TIME_LIMITED && (
                <div
                  className="is-flex is-align-items-center mt-4 mb-5"
                  style={{ display: 'none' }}
                >
                  <Controller
                    name="timeLimited"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        disabled={isReadOnly}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          setIsTimeLimitedSwitchOn(e);
                        }}
                        onColor="#EC9A1E"
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    )}
                  />
                  <label className="ml-2">
                    {getValues('timeLimited')
                      ? t(['forms.exercise.timeLimited'])
                      : t(['forms.exercise.noTimeLimited'])}
                  </label>
                </div>
              )}

              {isTimeLimitedSwitchOn && isReadOnly === false && (
                <>
                  <Controller
                    name="actionName"
                    control={control}
                    render={({ field }) => (
                      <>
                        <label className="label">{t(['forms.exercise.actionName'])}</label>
                        <Select
                          classNamePrefix="react-select"
                          value={field.value}
                          onChange={field.onChange}
                          options={availableSelectStepOptions}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="actionDuration"
                    control={control}
                    render={({ field }) => (
                      <>
                        <label className="label">{t(['forms.exercise.actionDuration'])}</label>
                        <Select
                          classNamePrefix="react-select"
                          value={field.value}
                          onChange={field.onChange}
                          options={actionDurationOptions}
                        />
                      </>
                    )}
                  />
                  <Button
                    disabled={awaitingSaveNewStepApi}
                    type="button"
                    variant="outlined"
                    colour="black"
                    translationKey="forms.exercise.actionAdd"
                    onClick={() => addStep(getValues('actionName'), getValues('actionDuration'))}
                    additionalClassName="is-align-self-flex-end py-2 mb-5"
                  ></Button>
                  <div className="divider"></div>
                </>
              )}
              {isTimeLimitedSwitchOn && exerciseDetails && exerciseDetails.steps?.length > 0 && (
                <StepsList
                  onRemoveStep={removeStep}
                  steps={exerciseDetails.steps}
                  isReadOnly={isReadOnly}
                />
              )}
              {isTimeLimitedSwitchOn && (
                <Controller
                  name="reps"
                  control={control}
                  rules={{ maxLength: 100 }}
                  render={({ field }) => (
                    <>
                      <label className="label">{t(['forms.exercise.reps'])}</label>
                      <Select
                        isDisabled={isReadOnly}
                        menuPlacement="top"
                        classNamePrefix="react-select"
                        value={field.value}
                        onChange={field.onChange}
                        options={repsOptions}
                      />
                    </>
                  )}
                />
              )}
            </>
          )}
          {newExercise && programmeId && isReadOnly === false ? (
            <div className="buttons mb-5 is-align-self-flex-end my-2">
              <Button
                type="button"
                variant="filled"
                colour="gray"
                translationKey="forms.exercise.cancel"
                onClick={() => navigate(-1)}
                additionalClassName="mr-3 py-2 px-5"
              ></Button>
              <Button
                disabled={awaitingUpdatingProgrammeExecApi}
                type="button"
                variant="outlined"
                colour="black"
                translationKey="forms.exercise.save"
                onClick={(e: React.MouseEvent<HTMLElement>) => addExerciseToProgramme(e, exercise)}
                additionalClassName="py-2 "
              ></Button>
            </div>
          ) : programmeId && isReadOnly === false ? (
            <div className="buttons mb-5 is-align-self-flex-end my-2">
              <Button
                type="button"
                variant="filled"
                colour="gray"
                translationKey="forms.exercise.cancel"
                onClick={() => navigate(-1)}
                additionalClassName="mr-3 py-2 px-5"
              ></Button>
              <Button
                type="button"
                variant="outlined"
                colour="orange"
                translationKey="forms.exercise.delete"
                onClick={(e: React.MouseEvent<HTMLElement>) => handleDeleteExercise(e, exerciseId)}
                additionalClassName="mr-3 py-2 px-5"
              ></Button>
              <Button
                disabled={awaitingUpdatingProgrammeExecApi}
                type="submit"
                variant="outlined"
                colour="black"
                translationKey="forms.exercise.save"
                onClick={() => null}
                additionalClassName="py-2 "
              ></Button>
            </div>
          ) : (
            <div className="buttons mb-5 is-align-self-flex-end my-2">
              <Button
                type="button"
                variant="outlined"
                colour="black"
                translationKey="forms.exercise.back"
                onClick={() => navigate(-1)}
                additionalClassName="mr-3 py-2 px-5"
              ></Button>
            </div>
          )}
        </form>
      </div>
      <div className="column is-5 mt-6 ml-3">
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            url={exercise?.urlVideo}
            controls={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  onContextMenu: (e: any) => e.preventDefault(),
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExerciseForm;
