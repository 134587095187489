export interface TabDetails {
  titleKey: string;
  id: string;
}

export const tabs: TabDetails[] = [
  {
    titleKey: 'profile',
    id: 'profile',
  },
  {
    titleKey: 'exercise-plan',
    id: 'exercisePlan',
  },
  {
    titleKey: 'plan-activity',
    id: 'planHistory',
  },
  {
    titleKey: 'physios',
    id: 'physios',
  },
];
