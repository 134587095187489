import Markdown from 'markdown-to-jsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLanguagePreference } from '../../hooks/useLanguagePreference';
import { PageLayout } from '../../layout/PageLayout';
import './HelpPages.scss';

const TermsOfUse = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();
  const [termsDocument, setTermsDocument] = React.useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userLanguagePreference !== null) {
      import(`./terms-markdown/terms_${userLanguagePreference}.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => setTermsDocument(res))
            .catch(() => navigate('/page-not-found'));
        })
        .catch(() => navigate('/page-not-found'));
    }
  }, [userLanguagePreference, navigate]);

  return (
    <div className="help-pages">
      <PageLayout headerTitle={t('help-pages.terms-title')}>
        <Markdown>{termsDocument}</Markdown>
      </PageLayout>
    </div>
  );
};

export default TermsOfUse;
