import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useLanguagePreference } from '../../hooks/useLanguagePreference';
interface Props {
  component: any;
  props?: any;
}

export const ProtectedRoute = ({ component, props }: React.PropsWithChildren<Props>) => {
  const {
    context: { userLanguagePreference, languageOptions },
  } = useLanguagePreference();
  const requiresPhysioConfirmation = languageOptions?.find(
    (e) => e.value === userLanguagePreference,
  )?.requiresPhysioConfirmation;

  const Component = withAuthenticationRequired(component, {
    loginOptions: { ui_locales: userLanguagePreference ?? 'en-GB', requiresPhysioConfirmation },
  });
  return <Component {...props} />;
};
