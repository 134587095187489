import React from 'react';
import { useTranslation } from 'react-i18next';
import html from '../../../tools/html';
import './Button.scss';

// TODO needs revisiting - a lot of the css is specific
// to certain usages of these buttons - see P0936-505
type Props = {
  translationKey: string;
  variant: 'filled' | 'outlined' | 'no-border';
  colour: 'black' | 'orange' | 'gray';
  icon?: string;
  additionalClassName?: string;
  onClick?: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
  type: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
};

export const Button = ({
  translationKey,
  variant,
  colour,
  icon,
  additionalClassName,
  onClick,
  type,
  disabled,
  children,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const className = `btn btn-${variant} btn-${variant}--${colour} ${additionalClassName ?? ''} ${
    icon ? 'btn--has-icon' : ''
  }`;

  return (
    <button className={className} onClick={onClick} type={type} disabled={disabled}>
      {children ? (
        children
      ) : (
        <>
          {icon && <img src={icon} alt="" />}
          {html(t(translationKey))}
        </>
      )}
    </button>
  );
};
