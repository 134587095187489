import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header';
import './Layout.scss';

interface PageLayoutProps {
  showHero?: boolean;
  showHeader?: boolean;
  headerTitle?: string; // TODO IS THERE A BETTER WAY THAN THESE OPTIONAL PROPS - ONLY NEEDED IF SHOWHEADER
  headerBackBtnClick?: () => void;
  headerBackBtnText?: string;
}

export const PageLayout = ({
  showHero = false,
  showHeader = true,
  headerBackBtnClick = () => {},
  headerBackBtnText = '',
  headerTitle = '',
  children,
}: React.PropsWithChildren<PageLayoutProps>) => {
  const { t } = useTranslation();
  return (
    <>
      {showHero && (
        <div className="page-layout__hero">
          <div className="page-layout__hero-title">
            <h1>{t('home.hero')}</h1>
            <p>{t('home.intro')}</p>
          </div>
        </div>
      )}
      {showHeader && (
        <Header
          title={headerTitle}
          backButtonOnClick={headerBackBtnClick}
          backButtonText={headerBackBtnText}
        />
      )}
      <div className="page-layout__content">{children}</div>
    </>
  );
};
