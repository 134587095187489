import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create(
  {
    /* stylelint-disable */
    page: {
      flexDirection: 'column',
      flexGrow: 1,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 75,
      paddingTop: 75,
      fontFamily: 'Open Sans',
    },
    banner: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #D3D3D3',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 20,
      paddingTop: 20,
    },
    bannerDate: {
      fontSize: 12,
      fontWeight: 'normal',
    },
    image: {
      width: 200,
    },
    detailsCol: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginBottom: 20,
    },
    publishedDate: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 5,
    },
    reviewDate: {
      fontSize: 10,
      fontWeight: 'normal',
    },
    heading: {
      fontSize: 18,
      fontWeight: 800,
      marginTop: 20,
      marginBottom: 20,
    },
    exercise: {
      marginBottom: 15,
    },
    bodyText: {
      fontSize: 14,
    },
    stepText: {
      paddingTop: 5,
      fontSize: 10,
    },
    footer: {
      backgroundColor: '#FAFAFA',
      padding: 20,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    footerText: {
      fontWeight: 700,
      fontSize: 14,
      textAlign: 'center',
    },
  } /* stylelint-enable */,
);
