import React, { useState } from 'react';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { Button } from '../../FormComponents/Buttons/Button';
import { useTranslation } from 'react-i18next';
import {
  getFriendlyLocalLongDate,
  getFriendlyLocalShortDate,
} from '../../../helpers/dateFormatHelper';
import './EditCurrentPlan.scss';
import EditExcercises from '../EditExcercises/EditExcercises';
import { useLocation, useNavigate } from 'react-router-dom';
import { PatientResponse } from '../../../types/responses';
import { DownloadPdfModal } from '../../Modals/DownloadPdfModal/DownloadPdfModal';
import { useLanguagePreference } from '../../../hooks/useLanguagePreference';

interface EditCurrentPlanProps {
  patientDetails: PatientResponse;
  draftProgramme: ProgrammeDetails;
  publishedProgramme: ProgrammeDetails | undefined;
  handleContinue: () => void;
}

export const EditCurrentPlan = ({
  draftProgramme,
  publishedProgramme,
  handleContinue,
  patientDetails,
}: EditCurrentPlanProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [downloadPdf, setDownloadPdf] = useState(false);

  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();

  if (downloadPdf && publishedProgramme) {
    return (
      <DownloadPdfModal
        isOpen={downloadPdf}
        handleCloseModal={() => setDownloadPdf(false)}
        patientDetails={patientDetails}
        programme={publishedProgramme}
      />
    );
  }

  return (
    <>
      <div className="edit-current-plan__button-wrapper">
        <Button
          onClick={() => navigate(`${location.pathname}/programme/${draftProgramme.programmeId}`)}
          type="button"
          translationKey="forms.patient.programme-actions.add-exercise"
          variant="outlined"
          colour="black"
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
        />
        <Button
          onClick={() => setDownloadPdf(true)}
          type="button"
          translationKey="forms.patient.programme-actions.export-plan"
          variant="outlined"
          colour="black"
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
          disabled={!publishedProgramme}
        />
      </div>
      <h1 className="edit-current-plan__overview-title">
        {t('forms.patient.programme-actions.published-heading')}
      </h1>
      {publishedProgramme?.publishedOnUtc &&
        !publishedProgramme.publishedOnUtc.includes('0001') && (
          <p className="edit-current-plan__overview-last-published">
            {t('forms.patient.programme-actions.last-published')}{' '}
            {getFriendlyLocalLongDate(publishedProgramme.publishedOnUtc, userLanguagePreference)}{' '}
            {t('forms.patient.programme-actions.last-published-by')}{' '}
            {publishedProgramme.publishedByUserName}
          </p>
        )}
      {publishedProgramme?.reviewDateUtc && !publishedProgramme.reviewDateUtc.includes('0001') && (
        <p className="edit-current-plan__overview-review">
          {t('forms.patient.programme-actions.plan-review-date-label')}{' '}
          {getFriendlyLocalShortDate(publishedProgramme.reviewDateUtc, userLanguagePreference)}
        </p>
      )}
      {draftProgramme.exercises.length > 0 ? (
        <div className="edit-current-plan__exercises-list">
          <EditExcercises draftProgramme={draftProgramme} />
        </div>
      ) : (
        <p>{t('forms.patient.programme-actions.no-exercises')}</p>
      )}
      <div className="edit-current-plan__button-wrapper">
        <Button
          type="button"
          translationKey="forms.patient.programme-actions.continue-button"
          variant="outlined"
          colour="black"
          onClick={handleContinue}
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
          disabled={draftProgramme.exercises.length === 0}
        ></Button>
      </div>
    </>
  );
};
