import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from 'restful-react';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { PatientUserResponse } from '../../../types/PhysioDetails';
import './TabContent.scss';
import html from '../../../tools/html';

interface PhysioContactCardsProps {
  patientId: string;
}

export const PhysioContactCards = ({ patientId }: PhysioContactCardsProps) => {
  const { t } = useTranslation();

  const {
    data: activePatientUserDetails,
    error: errorGettingPatientUserData,
    loading,
  } = useGet({
    path: `patientuser/active/${patientId}`,
  });

  if (errorGettingPatientUserData) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <div className="columns">
      <div className="column is-12">
        <h2 className="tab-content__heading mb-4">
          {html(t('forms.patient.tab-headings.physios'))}
        </h2>
        {loading ? (
          <LoadingSpinner isLocal={true} />
        ) : (
          <>
            {activePatientUserDetails.patientUsers.length === 0 ? (
              <p>{t('forms.patient.physiotherapists.no-others')}</p>
            ) : (
              <div className="physios__card-layout">
                {activePatientUserDetails.patientUsers.map((item: PatientUserResponse) => (
                  <div className="physios__card" key={item.id}>
                    <p className="physios__card__name">
                      {item.userDetails.contactName ||
                        `${item.inviteeFirstName ? item.inviteeFirstName : ''} ${
                          item.inviteeLastName ? item.inviteeLastName : ''
                        }`}
                    </p>
                    <p>
                      {item.userDetails.contactEmail ||
                        (item.inviteeEmail ? item.inviteeEmail : '')}
                    </p>
                    <p>{item.userDetails.contactPhoneNumber}</p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
