import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import TranslationHtml from '../TranslationHtml/TranslationHtml';
import './CookieBar.scss';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';

interface CookieBarProps {
  siteCookies: string[];
  gaAccount: string;
  daysUntilExpiryAccept: number;
  daysUntilExpiryDecline: number;
}

export const CookieBar = ({
  siteCookies,
  gaAccount,
  daysUntilExpiryAccept,
  daysUntilExpiryDecline,
}: CookieBarProps) => {
  const { t } = useTranslation();
  const { setGaCode } = useGoogleAnalytics();
  const [, setCookie, removeCookie] = useCookies([...siteCookies, 'acceptCookie']);

  const handleAccept = () => {
    const expires = new Date();
    expires.setTime(expires.getTime() + daysUntilExpiryAccept * 24 * 60 * 60 * 1000);
    setCookie('acceptCookie', 'true', { expires });
    setGaCode(gaAccount);
  };

  const handleDecline = () => {
    siteCookies.forEach((cookie) => removeCookie(cookie, { domain: document.domain }));
    const expires = new Date();
    expires.setTime(expires.getTime() + daysUntilExpiryDecline * 24 * 60 * 60 * 1000);
    setCookie('acceptCookie', 'false');
  };

  return (
    <div className="ew-cookie-bar ew-cookie-bar--show-bottom ew-cookie-bar--animate">
      <div className="ew-cookie-bar__container">
        <div className="ew-cookie-bar__cookie-message-body">
          <TranslationHtml htmlString={t('cookiebar.messageHtml')} />
        </div>
        <div className="ew-cookie-bar__cookie-button-body">
          <button
            className="ew-cookie-bar__button ew-cookie-bar__button-accept"
            onClick={handleAccept}
          >
            <TranslationHtml htmlString={t('cookiebar.buttonAcceptText')} />
          </button>
          <button
            className="ew-cookie-bar__button ew-cookie-bar__button-decline"
            onClick={handleDecline}
          >
            <TranslationHtml htmlString={t('cookiebar.buttonDeclineText')} />
          </button>
        </div>
      </div>
    </div>
  );
};
