import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../FormComponents/Buttons/Button';
import './InviteToken.scss';

type Props = {
  token: string;
  isActive: boolean;
  onClick: () => void;
};

const InviteToken = ({ token, isActive, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="token-container">
      <div className="token-container__details">
        <h2>{t('forms.patient.generate-code.code-main-text')}</h2>
      </div>
      <div className="token-container__actions">
        <Button
          disabled={!isActive}
          onClick={onClick}
          additionalClassName="py-2"
          type="button"
          variant="outlined"
          colour="black"
          translationKey="forms.patient.generate-code.code-button-text"
        />
      </div>
    </div>
  );
};

export default InviteToken;
