import { Auth0Provider } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import env from './Env';

interface Props {
  children: ReactNode;
}
export const Auth0ProviderWithNavigate = ({ children }: Props) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: env.authRedirect,
      }}
      onRedirectCallback={onRedirectCallback}
      domain={env.authDomain}
      clientId={env.authClientId}
      redirectUri={env.authRedirect}
      audience={env.authAudience}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      scope="read:current_user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};
