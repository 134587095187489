import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieBar } from '../components/CookieBar/CookieBar';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import './Layout.scss';
import { useLocation } from 'react-router-dom';

interface LayoutProps {
  cookies: any;
  gaCode: string;
}

export const MainLayout = ({ cookies, gaCode, children }: React.PropsWithChildren<LayoutProps>) => {
  const { t } = useTranslation();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="main-layout__page-container">
      <Navbar />
      <div className="main-layout__main">
        <div className="main-layout__left-pattern"></div>
        <div className="main-layout__main-content">{children}</div>
      </div>
      <Footer jobCode={t('footer.job-code')} />
      {!cookies.acceptCookie && (
        <CookieBar
          siteCookies={['_ga', `_ga_${gaCode?.split('-').pop() || ''}`]}
          gaAccount={gaCode}
          daysUntilExpiryAccept={365}
          daysUntilExpiryDecline={90}
        />
      )}
    </div>
  );
};
