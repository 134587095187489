import React from 'react';
import './LoadingSpinner.scss';

type LoadingSpinnerProps = {
  isLocal?: boolean;
};

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <div className={`${props.isLocal ? 'loading-spinner__local' : 'loading-spinner__overlay'}`}>
      <div data-testid="loading-spinner" className="loading-spinner">
        <div
          className={`lds-ring ${
            props.isLocal ? 'lds-ring-blue lds-ring-small' : 'lds-ring-white'
          }`}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
