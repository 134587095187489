import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  required?: boolean;
}

export const ControlledLabelNoInput = <T extends FieldValues>({
  control,
  name,
  label,
  required,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required || false, maxLength: 2000 }}
      render={({ field }) => (
        <>
          <label>
            <strong>{label}</strong>
          </label>
          <br />
          <pre className="prereadonly">{field.value}</pre>
          <br />
        </>
      )}
    />
  );
};
