import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutate } from 'restful-react';
import { INVITE_ID_KEY } from '../../../constants';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { Button } from '../../FormComponents/Buttons/Button';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import { BaseModal } from '../BaseModal/BaseModal';
import { SuccessModal } from '../SuccessModal/SuccessModal';
import './UserInviteModal.scss';

interface Props {
  isOpen: boolean;
  handleCloseModal: () => void;
  inviteCode: string;
}

const GUIDCHECK = new RegExp(
  '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
  'i',
);

export const UserInviteModal = ({ isOpen, handleCloseModal, inviteCode }: Props) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(inviteCode ? inviteCode : '');
  const [errorMessageKey, setErrorMessageKey] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleOnChange = (data: string) => {
    setToken(data);
  };

  const onSuccess = (res: unknown) => {
    setShowSuccessModal(true);
  };

  const handleCloseAll = () => {
    setShowSuccessModal(false);
    handleCloseModal();
  };

  const {
    mutate: acceptUserInvite,
    loading: awaitingSubmitApiCall,
    error: errorAcceptingInvite,
  } = useMutate({
    verb: 'PATCH',
    path: `/patientuser/accept/${token}`,
    onMutate: onSuccess,
  });

  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (GUIDCHECK.test(token)) {
      localStorage.removeItem(INVITE_ID_KEY);
      acceptUserInvite(token);
    } else {
      setErrorMessageKey('forms.patient.user-invite-modal.invalid-guid');
    }
  };

  if (errorAcceptingInvite) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (showSuccessModal) {
    return (
      <SuccessModal
        isOpen={showSuccessModal}
        handleCloseModal={handleCloseAll}
        translationKey={'forms.patient.user-invite-modal.success'}
      />
    );
  }

  return (
    <BaseModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      ariaHideApp={true}
      title={t(['forms.patient.user-invite-modal.title'])}
    >
      <div>
        <p className="is-size-6">{t(['forms.patient.user-invite-modal.instructions'])}</p>
        <form onSubmit={handleSubmit}>
          <TextInput
            placeholder={''}
            value={token}
            onChange={handleOnChange}
            errorType={errorMessageKey ? { type: 'error' } : undefined}
            errorMessageKey={errorMessageKey}
          ></TextInput>
          <div className="button-wrapper">
            <Button
              type="button"
              translationKey="forms.patient.cancel"
              variant="filled"
              colour="gray"
              onClick={handleCloseModal}
              additionalClassName="mr-3 py-2 px-5"
            ></Button>
            <Button
              disabled={awaitingSubmitApiCall}
              type="submit"
              translationKey="forms.patient.submit"
              variant="outlined"
              colour="black"
              additionalClassName="py-2 px-5"
            ></Button>
          </div>
        </form>
      </div>
    </BaseModal>
  );
};
