import React, { useState } from 'react';
import './PublishPlan.scss';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { useTranslation } from 'react-i18next';
import { useMutate } from 'restful-react';
import { Button } from '../../FormComponents/Buttons/Button';
import { SuccessModal } from '../../Modals/SuccessModal/SuccessModal';
import { getFriendlyLocalShortDate } from '../../../helpers/dateFormatHelper';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useGoogleAnalytics } from '../../../hooks/useGoogleAnalytics';
import { useLanguagePreference } from '../../../hooks/useLanguagePreference';

type Props = {
  draftProgramme: ProgrammeDetails;
  handleGoToFirstStep: () => void;
  handleBackButtonClick: () => void;
  refetchPatientData: () => void;
};

const PublishPlan = ({
  draftProgramme,
  handleGoToFirstStep,
  handleBackButtonClick,
  refetchPatientData,
}: Props) => {
  const { t } = useTranslation();
  const { raiseEvent } = useGoogleAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();

  const onPublishSuccess = (body: unknown, data: unknown) => {
    raiseEvent('Programme', 'Programme published');
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    refetchPatientData();
    handleGoToFirstStep();
  };

  const { mutate: publishPlan, error: errorSavingPlan } = useMutate({
    verb: 'POST',
    path: `/programme/${draftProgramme?.programmeId}/publish`,
    onMutate: onPublishSuccess,
  });

  const handlePublishPlan = () => {
    publishPlan(draftProgramme?.programmeId);
  };

  if (errorSavingPlan) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (isModalOpen) {
    return (
      <SuccessModal
        isOpen={isModalOpen}
        handleCloseModal={() => onModalClose()}
        translationKey={'forms.patient.publish-modal.title'}
      />
    );
  }

  return (
    <>
      <div className="publish-plan__overview">
        <div className="publish-plan__overview-info">
          <div className="columns">
            <div className="column is-10">
              <h1 className="publish-plan__overview-title">
                {t('forms.patient.programme-actions.edit-heading')}
              </h1>
              <div>
                {draftProgramme.exercises &&
                  draftProgramme.exercises[0] &&
                  draftProgramme.exercises
                    .sort(function (a, b) {
                      var sequenceA = a.sequence,
                        sequenceB = b.sequence;
                      return sequenceA - sequenceB;
                    })
                    .map((exercise) => (
                      <p
                        className="publish-plan__overview-entries"
                        key={exercise.programmeExerciseId}
                      >
                        {exercise.title}
                      </p>
                    ))}
              </div>
            </div>
            <div className="column is-2">
              <p className="publish-plan__clickable-link" onClick={handleGoToFirstStep}>
                {t('forms.patient.programme-actions.edit')}
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-10">
              <h1 className="publish-plan__overview-title">
                {t('forms.patient.programme-actions.review-date-heading')}
              </h1>
              {draftProgramme.reviewDateUtc.includes('0001') ? (
                <p>
                  <em>{t('forms.patient.programme-actions.review-date-not-set')}</em>
                </p>
              ) : (
                <p>
                  {getFriendlyLocalShortDate(draftProgramme.reviewDateUtc, userLanguagePreference)}
                </p>
              )}
            </div>
            <div className="column is-2">
              <p className="publish-plan__clickable-link" onClick={handleBackButtonClick}>
                {t('forms.patient.programme-actions.edit')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="publish-plan__button-wrapper">
        <Button
          type="button"
          translationKey="forms.patient.programme-actions.back-button"
          variant="outlined"
          colour="black"
          onClick={handleBackButtonClick}
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
        ></Button>
        <Button
          type="button"
          translationKey="forms.patient.programme-actions.publish-plan"
          variant="outlined"
          colour="black"
          onClick={handlePublishPlan}
          additionalClassName="py-2 px-5 has-content-centered is-medium-fixed-width"
          disabled={draftProgramme.exercises.length === 0}
        ></Button>
      </div>
    </>
  );
};

export default PublishPlan;
