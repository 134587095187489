import moment from 'moment';
import 'moment/min/locales';

export const getLocalDateFromUtc = (utcDateTime: string | undefined) => {
  if (!utcDateTime || utcDateTime === '0001-01-01T00:00:00') {
    return '';
  }
  const localDateFromUtc = moment.utc(utcDateTime).local().format('YYYY/MM/DD HH:mm:ss');
  return localDateFromUtc;
};

export const getTimeFromNow = (date: string, locale: string | null) => {
  return moment(date)
    .locale(locale || 'en-GB')
    .fromNow();
};

export const getUtcFromLocalDate = (localDateTime: string | undefined) => {
  const utcDateFromLocal = moment(localDateTime)
    .add(moment(localDateTime).utcOffset(), 'm')
    .utc()
    .format();
  return utcDateFromLocal;
};

export const getFriendlyLocalLongDate = (date: string, locale: string | null) => {
  const localDate = getLocalDateFromUtc(date);
  const dateObject = new Date(localDate);
  const friendlyDate = new Date(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    dateObject.getHours(),
    dateObject.getMinutes(),
  ).toLocaleDateString(locale || 'en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return friendlyDate;
};

export const getFriendlyLocalShortDate = (date: string, locale: string | null) => {
  const localDate = getLocalDateFromUtc(date);
  const dateObject = new Date(localDate);
  const friendlyDate = new Date(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
  ).toLocaleDateString(locale || 'en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  return friendlyDate;
};
