import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

/**
 * Encapsulates react-ga4 functionality.
 * Note: since only has the gaCode as state (not context) then the ga code
 * will not always be available if components only use the raiseEvent function, but events
 * work using the ga cookie/script as long as GA has been initialised (on App
 * load or accept cookies).
 * If we wanted to check gaCode is present/initialised before using raiseEvent could add
 * context for gaCode along the lines of the useLanguagePreference hook, but that
 * doesn't feel necessary right now.
 */
export const useGoogleAnalytics = () => {
  const [gaCode, setGaCode] = useState('');

  useEffect(() => {
    if (gaCode) {
      ReactGA.initialize(gaCode);
    }
  }, [gaCode]);

  const raiseEvent = (category: string, action: string) => {
    ReactGA.event({
      category: category,
      action: action,
    });
  };

  return { setGaCode, raiseEvent };
};
