import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../FormComponents/Buttons/Button';
import './ExerciseCard.scss';
import DeleteIconOutlined from '../../images/icon-delete--outlined.svg';

export type ExerciseCardProps = {
  id: string;
  title: string;
  img: string;
  description?: string;
  introductory?: boolean;
  additionalClassName?: string;
  programmeId?: string;
  onClick?: () => void;
  onDelete?:
    | ((e: React.MouseEvent<HTMLElement>, id: string, exerciseTitle: string) => void)
    | (() => void);
  isEditingPlan: boolean;
  isExerciseViewOnly?: boolean;
  isDeleteButtonDisabled?: boolean;
};

const ExerciseCard = ({
  id,
  title,
  img,
  description,
  introductory,
  additionalClassName,
  programmeId,
  onClick,
  onDelete,
  isEditingPlan,
  isExerciseViewOnly = false,
  isDeleteButtonDisabled,
}: ExerciseCardProps) => {
  const navigate = useNavigate();
  const exerciseLink = programmeId
    ? `/programme/${programmeId}/exercise/${id}`
    : `/exercises/${id}`;

  return (
    <div
      onClick={
        onClick ? () => onClick() : () => navigate(exerciseLink + '?readonly=' + isExerciseViewOnly)
      }
      className={`exercise-card ${introductory ? 'is-intro' : ''} ${additionalClassName ?? ''}`}
    >
      <img className="exercise-card__image" src={img} alt="exercise thumbnail" title={title} />
      <div className="exercise-card__details">
        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
          <p className="exercise-card__details__title" title={title}>
            {title}
          </p>
          {additionalClassName?.includes('is-added') && (
            <div className="added-label">
              <p>Added</p>
            </div>
          )}
          {programmeId && isEditingPlan && (
            <Button
              disabled={isDeleteButtonDisabled != null ? isDeleteButtonDisabled : false}
              onClick={onDelete && ((e: React.MouseEvent<HTMLElement>) => onDelete(e, id, title))}
              type="button"
              variant="outlined"
              colour="orange"
              translationKey="forms.patient.programme-actions.delete"
              icon={DeleteIconOutlined}
              additionalClassName="is-reversed py-1"
            />
          )}
        </div>
        <p className="exercise-card__details__description" title={title}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default ExerciseCard;
