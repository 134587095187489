import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguagePreference } from '../../../hooks/useLanguagePreference';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { PatientResponse } from '../../../types/responses';
import { Button } from '../../FormComponents/Buttons/Button';
import { PdfDocument } from '../../PdfPlan/PdfPlan';
import { BaseModal } from '../BaseModal/BaseModal';
import './DownloadPdfModal.scss';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  programme: ProgrammeDetails;
  patientDetails: PatientResponse;
};

export const DownloadPdfModal = ({
  isOpen,
  handleCloseModal,
  programme,
  patientDetails,
}: Props) => {
  const { t } = useTranslation();
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();

  return (
    <BaseModal isOpen={isOpen} handleCloseModal={handleCloseModal} ariaHideApp={true} title="">
      <div className="download-modal__container">
        <p>{t('pdfPlan.download-message')}</p>
        <Button
          colour="black"
          variant="outlined"
          type="button"
          translationKey=""
          additionalClassName={'py-2 px-5 has-content-centered is-medium-fixed-width mt-4'}
        >
          <PDFDownloadLink
            document={
              <PdfDocument
                programme={programme}
                patientDetails={patientDetails}
                userLanguagePreference={userLanguagePreference}
              />
            }
            fileName="plan.pdf"
            className="has-text-black"
          >
            {({ blob, url, loading, error }) =>
              loading ? `${t('pdfPlan.loading')}` : `${t('pdfPlan.download')}`
            }
          </PDFDownloadLink>
        </Button>
      </div>
    </BaseModal>
  );
};
