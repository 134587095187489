import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavSidebar, NavSidebarItem } from '../../../components/NavSidebar/NavSidebar';
import { EditCurrentPlan } from '../../../components/Programme/EditCurrentPlan/EditCurrentPlan';
import PublishPlan from '../../../components/Programme/PublishPlan/PublishPlan';
import ReviewDate from '../../../components/Programme/ReviewDate/ReviewDate';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { PatientResponse } from '../../../types/responses';
import './TabContent.scss';
import html from '../../../tools/html';

interface ExercisePlanProps {
  patientDetails: PatientResponse;
  draftProgramme: ProgrammeDetails;
  publishedProgramme: ProgrammeDetails | undefined;
  refetchPatientData: () => void;
}

export const ExercisePlan = ({
  draftProgramme,
  publishedProgramme,
  patientDetails,
  refetchPatientData,
}: ExercisePlanProps) => {
  const { t } = useTranslation();
  const navItems: NavSidebarItem[] = [
    {
      id: 'currentPlan',
      name: t('forms.patient.programme-actions.published-heading'),
    },
    {
      id: 'planReviewDate',
      name: t('forms.patient.programme-actions.plan-review-date'),
    },
    {
      id: 'publishPlan',
      name: t('forms.patient.programme-actions.publish-plan'),
    },
  ];

  const [currentStepId, setCurrentStepId] = useState(navItems[0].id);

  const handleSetEditPlanStep = (stepNumber: number) => {
    setCurrentStepId(navItems[stepNumber].id);
  };

  return (
    <div className="columns">
      <div className="column is-3">
        <h2 className="tab-content__heading mb-4">
          {html(t('forms.patient.tab-headings.exercise-plan'))}
        </h2>
        <NavSidebar
          items={navItems}
          activeItemId={currentStepId}
          setActiveItem={setCurrentStepId}
        />
      </div>
      <div className="column is-8">
        {currentStepId === navItems[0].id && (
          <EditCurrentPlan
            draftProgramme={draftProgramme}
            publishedProgramme={publishedProgramme}
            handleContinue={() => handleSetEditPlanStep(1)}
            patientDetails={patientDetails}
          />
        )}
        {currentStepId === navItems[1].id && (
          <>
            <ReviewDate
              draftProgramme={draftProgramme}
              handleBackButtonClick={() => handleSetEditPlanStep(0)}
              handleContinueButtonClick={() => handleSetEditPlanStep(2)}
            />
          </>
        )}
        {currentStepId === navItems[2].id && (
          <>
            <PublishPlan
              draftProgramme={draftProgramme}
              handleBackButtonClick={() => handleSetEditPlanStep(1)}
              handleGoToFirstStep={() => handleSetEditPlanStep(0)}
              refetchPatientData={refetchPatientData}
            />
          </>
        )}
      </div>
    </div>
  );
};
