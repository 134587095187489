import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabDetails } from './tabConfig';
import './TabList.scss';
import html from '../../../tools/html';

interface TabListProps {
  tabs: TabDetails[];
  setSelectedTabId: (id: string) => void;
  selectedTabId: string;
}

export const TabList = ({ tabs, setSelectedTabId, selectedTabId }: TabListProps) => {
  const { t } = useTranslation();
  return (
    <ul className="tab-list__container">
      {tabs.map((tab) => (
        <li key={tab.id}>
          <button
            className={
              tab.id === selectedTabId ? 'tab-list__btn tab-list__btn_active' : 'tab-list__btn'
            }
            onClick={() => setSelectedTabId(tab.id)}
          >
            {html(t(`forms.patient.tab-headings.${tab.titleKey}`))}
          </button>
        </li>
      ))}
    </ul>
  );
};
