import React from 'react';
import DeleteIcon from '../../images/icon-delete.svg';

import './Step.scss';

export type StepProps = {
  name: string;
  duration: number;
  onRemoveClick: (stepId: string) => void;
  id: string;
  isReadOnly: boolean;
};

const Step = ({ name, duration, onRemoveClick, id, isReadOnly }: StepProps) => {
  return (
    <div className="step">
      <div className="step__details">
        {name} - {duration} seconds
      </div>
      {!isReadOnly && (
        <div className="step__actions" onClick={() => onRemoveClick(id)}>
          <img src={DeleteIcon} alt="delete step" />
        </div>
      )}
    </div>
  );
};

export default Step;
