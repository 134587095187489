import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGet, useMutate } from 'restful-react';
import { useNavigate } from 'react-router';

import { OkResponse } from '../../types/responses';
import { ProgrammeDetails } from '../../types/ExerciseDetails';

import { useState } from 'react';
import { InviteTokenModal } from '../../components/Modals/InviteTokenModal/InviteTokenModal';
import { useLanguagePreference } from '../../hooks/useLanguagePreference';
import { DeletePatientModal } from '../../components/Modals/DeletePatientModal/DeletePatientModal';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { TabList } from './TabLayout/TabList';
import { tabs } from './TabLayout/tabConfig';
import { PatientProfile } from './TabContent/PatientProfile';
import { PatientPayload } from '../../types/PatientDetails';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { ExercisePlan } from './TabContent/ExercisePlan';
import { CreatePlan } from '../../components/Programme/CreatePlan/CreatePlan';
import { useLocation } from 'react-router-dom';
import { PlanActivity } from './TabContent/PlanActivity';
import { PhysioContactCards } from './TabContent/PhysioContactCards';
import { PageLayout } from '../../layout/PageLayout';

type RouterParams = {
  patientId: string;
};

type NewPatientResponse = {
  statusCode: number;
  response: string;
  patientId: string;
  name: string;
  age: string;
  notes: string;
};

const Patient = () => {
  const { t } = useTranslation();
  const { raiseEvent } = useGoogleAnalytics();
  const navigate = useNavigate();
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [isDeletePatientModalOpen, setIsDeletePatientModalOpen] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);

  const { patientId } = useParams<RouterParams>();
  const search = useLocation().search;
  const editingQueryString = new URLSearchParams(search).get('editing');

  useEffect(() => {
    if (editingQueryString) {
      setSelectedTabId(tabs[1].id);
    }
  }, [editingQueryString]);

  const {
    data: patientDetails,
    error: errorGettingPatientData,
    refetch: getPatientDetails,
  } = useGet({
    path: `patient/${patientId}`,
    lazy: true,
  });

  const {
    data: patientProgramme,
    loading,
    error: errorGettingPatientProgrammeData,
    refetch: getPatientProgrammeData,
  } = useGet({
    path: `/patient/${patientId}/programme`,
    resolve: (programme: OkResponse<ProgrammeDetails>) => {
      return programme.responses;
    },
    lazy: true,
  });

  const activeProgramme = patientProgramme?.find((p) => p.isActive);
  const draftProgramme = patientProgramme?.find((p) => p.isDraft);

  const onSuccessfulUpdate = () => {
    navigate('/');
  };

  const onSuccessfulCreate = (body: unknown, data: unknown) => {
    const typedData = data as NewPatientResponse;
    raiseEvent('Patient', 'Patient created');
    navigate(`/patient/${typedData.patientId}`);
  };

  const {
    mutate: saveNewPatient,
    loading: awaitingSaveApiCall,
    error: errorPostingPatient,
  } = useMutate<PatientPayload>({
    verb: 'POST',
    path: '/patient',
    onMutate: onSuccessfulCreate,
  });

  const {
    mutate: updatePatient,
    loading: awaitingUpdateApiCall,
    error: errorUpdatingPatient,
  } = useMutate<PatientPayload>({
    verb: 'PUT',
    path: '/patient',
    onMutate: onSuccessfulUpdate,
  });

  const handleBackBtnClick = () => {
    navigate('/');
  };

  useEffect(() => {
    if (patientId) {
      getPatientDetails();
      getPatientProgrammeData();
    }
  }, [getPatientDetails, getPatientProgrammeData, patientId]);

  if (
    errorPostingPatient ||
    errorUpdatingPatient ||
    errorGettingPatientData ||
    errorGettingPatientProgrammeData
  ) {
    return (
      <ErrorMessage
        message={t('error-messages.generic-error')}
        closeModalCallback={() => navigate('/')}
      />
    );
  }

  if (isDeletePatientModalOpen && patientId) {
    return (
      <DeletePatientModal
        isOpen={isDeletePatientModalOpen}
        handleCloseModal={() => setIsDeletePatientModalOpen(false)}
        patientId={patientId}
      />
    );
  }

  if (isTokenModalOpen && patientDetails && patientDetails.token && patientId) {
    return (
      <InviteTokenModal
        isOpen={isTokenModalOpen}
        patientId={patientId}
        token={patientDetails?.token}
        handleCloseModal={() => setIsTokenModalOpen(false)}
      />
    );
  }

  if ((patientId && !patientDetails) || loading) {
    return <LoadingSpinner isLocal={false} />;
  }

  return (
    <PageLayout
      headerTitle={t(['forms.patient.page-title'])}
      headerBackBtnClick={handleBackBtnClick}
      headerBackBtnText="navbar.back-clients"
    >
      <TabList
        tabs={tabs}
        setSelectedTabId={setSelectedTabId}
        selectedTabId={selectedTabId}
      ></TabList>
      {selectedTabId === tabs[0].id && (
        <PatientProfile
          patientDetails={patientDetails}
          saveNewPatient={saveNewPatient}
          updatePatient={updatePatient}
          isLoadingMutation={awaitingUpdateApiCall || awaitingSaveApiCall}
          handleOpenDeletePatientModal={setIsDeletePatientModalOpen}
          setIsTokenModalOpen={setIsTokenModalOpen}
          hasProgramme={patientProgramme != null && patientProgramme.length > 0}
          hasActiveProgramme={activeProgramme !== undefined}
        />
      )}
      {patientDetails && (
        <>
          {selectedTabId === tabs[1].id && draftProgramme ? (
            <ExercisePlan
              patientDetails={patientDetails}
              draftProgramme={draftProgramme}
              publishedProgramme={activeProgramme}
              refetchPatientData={getPatientProgrammeData}
            />
          ) : selectedTabId === tabs[1].id ? (
            <CreatePlan
              patientId={patientDetails.id}
              refetchPatientData={getPatientProgrammeData}
            />
          ) : null}
          {selectedTabId === tabs[2].id && (
            <PlanActivity
              patientDetails={patientDetails}
              userLanguagePreference={userLanguagePreference || ''}
            />
          )}
          {selectedTabId === tabs[3].id && <PhysioContactCards patientId={patientDetails.id} />}
        </>
      )}
    </PageLayout>
  );
};

export default Patient;
