import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepPayload } from '../../types/ExerciseDetails';
import Step from '../Step/Step';

type Props = {
  steps: StepPayload[];
  isReadOnly: boolean;
  onRemoveStep: (stepId: string) => void;
};

export const StepsList = ({ steps, isReadOnly, onRemoveStep }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <h2>
        <strong>{t(['forms.exercise.actionList'])}</strong>
      </h2>
      <div className="steps mt-4">
        {steps
          ?.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
          .map((step, i) => (
            <Step
              id={step.stepId || ''}
              key={i}
              name={step.name}
              duration={step.durationSeconds}
              onRemoveClick={onRemoveStep}
              isReadOnly={isReadOnly}
            />
          ))}
      </div>
    </>
  );
};
