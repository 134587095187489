import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutate } from 'restful-react';
import './EditExcercises.scss';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import ExerciseCard from '../../ExerciseCard/ExerciseCard';

import PlaceholderImg from '../../../images/ex_placeholder.jpg';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useGoogleAnalytics } from '../../../hooks/useGoogleAnalytics';

type Props = {
  draftProgramme: ProgrammeDetails;
};
// TODO ticket P0936-539 raised to look at the sorting of exercises before mapping to cards (which also happens in
// EditExercise & PlanModal), this should probably be moved to back end?
const EditExcercises = ({ draftProgramme }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { raiseEvent } = useGoogleAnalytics();
  const location = useLocation();

  const {
    mutate: deleteExerciseFromPlan,
    loading: deletingExercise,
    error: errorDeletingProgrammeExercise,
  } = useMutate({
    verb: 'DELETE',
    path: `/programme/removeexercise/${draftProgramme?.programmeId}`,
  });

  const handleDeleteExercise = async (
    e: React.MouseEvent<HTMLElement>,
    id: string,
    exerciseTitle: string,
  ) => {
    e.stopPropagation();
    await deleteExerciseFromPlan(id);
    raiseEvent(exerciseTitle, 'Exercise removed from plan');
    navigate(`${location.pathname}?editing=true`);
  };

  if (errorDeletingProgrammeExercise) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <>
      <div className="edit-exercises__exercises-list">
        {draftProgramme.exercises &&
          draftProgramme.exercises[0] &&
          draftProgramme.exercises
            .sort(function (a, b) {
              var sequenceA = a.sequence,
                sequenceB = b.sequence;
              return sequenceA - sequenceB;
            })
            .map((exercise) => (
              <ExerciseCard
                onDelete={handleDeleteExercise}
                programmeId={draftProgramme.programmeId}
                additionalClassName="full-width"
                key={exercise.programmeExerciseId}
                id={exercise.baseExerciseId}
                title={exercise.title}
                img={exercise.urlImage ? exercise.urlImage : PlaceholderImg}
                description={exercise.shortDescription}
                isEditingPlan={true}
                isDeleteButtonDisabled={deletingExercise}
              />
            ))}
      </div>
    </>
  );
};

export default EditExcercises;
