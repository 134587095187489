import React, { useState } from 'react';
import { BaseModal } from '../BaseModal/BaseModal';
import './InviteTokenModal.scss';

import CopyBtn from '../../../images/icon-copy.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '../../FormComponents/Buttons/Button';
import { useMutate } from 'restful-react';
import { useLanguagePreference } from '../../../hooks/useLanguagePreference';
import { Controller, useForm } from 'react-hook-form';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { SuccessModal } from '../SuccessModal/SuccessModal';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  patientId: string;
  token: string;
};

type Email = {
  emailAddress: string;
};

export const InviteTokenModal = ({ isOpen, handleCloseModal, patientId, token }: Props) => {
  const [tokenCopied, setTokenCopied] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();

  const { control, handleSubmit, reset, formState } = useForm<Email>({
    mode: 'onChange',
    defaultValues: {
      emailAddress: '',
    },
  });

  const handleCopyToken = () => {
    navigator.clipboard.writeText(token);
    setTokenCopied(true);
  };

  const onSuccess = () => {
    reset();
    setSuccess(true);
    return;
  };

  const onSubmit = (data: Email) => {
    sendInvite({
      patientEmail: data.emailAddress,
      patientId,
      userLocale: userLanguagePreference,
    });
  };

  const isValidEmailAddress = (email: string) => {
    if (email === '') {
      return true;
    }
    // eslint-disable-next-line
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const {
    mutate: sendInvite,
    loading: sendingInvite,
    error: errorSendingInvite,
  } = useMutate({
    verb: 'POST',
    path: `/patient/send-email`,
    onMutate: onSuccess,
  });

  const toggleForm = () => {
    setShowForm((prev) => {
      return !prev;
    });
  };

  if (errorSendingInvite) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (success) {
    return (
      <SuccessModal
        isOpen={success}
        handleCloseModal={handleCloseModal}
        translationKey={'forms.patient.token-modal.success'}
      />
    );
  }

  return (
    <BaseModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      ariaHideApp={true}
      title="forms.patient.token-modal.title"
    >
      <div className="invite-token-modal">
        <div>
          <p className="invite-token-modal__description-text">
            {t('forms.patient.token-modal.description')}
          </p>
        </div>
        <div className="invite-token-modal__token-box">
          <div className="invite-token-modal__token-box__token">{token}</div>
          <div className="invite-token-modal__token-box__copy-token-btn">
            <img src={CopyBtn} alt="Copy token" onClick={handleCopyToken} />
          </div>
        </div>
        {tokenCopied && (
          <p className="invite-token-modal__token-box__copy-notice">
            {t('forms.patient.token-modal.token-copied')}
          </p>
        )}
        <div className="buttons mt-4 invite-token-modal__button-bar">
          <Button
            onClick={() => handleCloseModal()}
            type="button"
            translationKey="forms.patient.token-modal.close-button"
            variant="filled"
            colour="gray"
            additionalClassName="py-2 px-2 is-140-wide"
          ></Button>
          <Button
            onClick={() => toggleForm()}
            type="button"
            translationKey="forms.patient.token-modal.email-button"
            variant="outlined"
            colour="black"
            additionalClassName="py-2 px-2 is-140-wide"
          ></Button>
        </div>
        <div className={`invite-token-modal__form ${showForm ? 'show' : ''}`}>
          {showForm && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="emailAddress"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => isValidEmailAddress(value),
                }}
                render={({ field }) => (
                  <TextInput
                    placeholder={t(['forms.placeholder'])}
                    label={t(['forms.physio-details.email'])}
                    value={field.value}
                    onChange={field.onChange}
                    errorType={formState.errors.emailAddress}
                    errorMessageKey="forms.physio-details.invalid-email"
                    validationPosition="after"
                  />
                )}
              />
              <Button
                disabled={!formState.isValid || errorSendingInvite || sendingInvite}
                type="submit"
                translationKey="forms.patient.submit"
                variant="outlined"
                colour="black"
                additionalClassName="py-2 px-2 is-140-wide"
              ></Button>
            </form>
          )}
        </div>
      </div>
    </BaseModal>
  );
};
