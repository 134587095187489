import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet } from 'restful-react';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { OkResponse, PatientResponse } from '../../../types/responses';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { getFriendlyLocalLongDate, getTimeFromNow } from '../../../helpers/dateFormatHelper';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { PlanModal } from '../../../components/Modals/PlanModal/PlanModal';
import html from '../../../tools/html';

interface PlanActivityProps {
  patientDetails: PatientResponse;
  userLanguagePreference: string;
}

export const PlanActivity = ({ patientDetails, userLanguagePreference }: PlanActivityProps) => {
  const { t } = useTranslation();
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<ProgrammeDetails | null>(null);

  const {
    data: programmeHistory,
    loading,
    error: errorGettingProgrammeHistory,
  } = useGet({
    path: `/programme/${patientDetails.id}/published`,
    resolve: (programme: OkResponse<ProgrammeDetails>) => {
      return programme.responses;
    },
  });

  const handleOpenPlan = (programmeId: string) => {
    const selected = programmeHistory?.filter((p) => p.programmeId === programmeId);
    if (selected?.length === 1) {
      setSelectedPlan(selected[0]);
      setIsPlanModalOpen(true);
    }
  };

  const handleClosePlan = () => {
    setIsPlanModalOpen(false);
    setSelectedPlan(null);
  };

  if (errorGettingProgrammeHistory) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (isPlanModalOpen && selectedPlan) {
    return (
      <PlanModal
        isOpen={isPlanModalOpen}
        handleCloseModal={() => handleClosePlan()}
        programme={selectedPlan}
        patientDetails={patientDetails}
      />
    );
  }

  return (
    <div className="columns">
      <div className="column is-8">
        <h2 className="tab-content__heading mb-4">
          {html(t('forms.patient.tab-headings.plan-activity'))}
        </h2>
        <div className="plan-activity">
          {loading ? (
            <LoadingSpinner isLocal={true} />
          ) : programmeHistory && programmeHistory.length > 0 ? (
            programmeHistory?.map((prog) => (
              <div className="plan-activity__card" key={prog.programmeId}>
                <p className="plan-activity__card__date">
                  {getTimeFromNow(prog.publishedOnUtc, userLanguagePreference)} -{' '}
                  {getFriendlyLocalLongDate(prog.publishedOnUtc, userLanguagePreference)}
                </p>
                <p>
                  {t('forms.patient.plan-activity.revision-name')} {prog.publishedByUserName}
                </p>
                <button
                  className="plan-activity__card__btn"
                  aria-label="open plan"
                  onClick={() => handleOpenPlan(prog.programmeId)}
                ></button>
              </div>
            ))
          ) : (
            <p>{t('forms.patient.plan-activity.no-activity')}</p>
          )}
        </div>
      </div>
    </div>
  );
};
