import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFriendlyLocalLongDate, getTimeFromNow } from '../../../helpers/dateFormatHelper';
import { ProgrammeDetails } from '../../../types/ExerciseDetails';
import { BaseModal } from '../BaseModal/BaseModal';
import PlaceholderImg from '../../../images/ex_placeholder.jpg';
import ExerciseCard from '../../ExerciseCard/ExerciseCard';
import { Button } from '../../FormComponents/Buttons/Button';
import './PlanModal.scss';
import { DownloadPdfModal } from '../DownloadPdfModal/DownloadPdfModal';
import { PatientResponse } from '../../../types/responses';
import { useLanguagePreference } from '../../../hooks/useLanguagePreference';
import html from '../../../tools/html';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  programme: ProgrammeDetails;
  patientDetails: PatientResponse;
};

// TODO ticket P0936-539 raised to look at the sorting of exercises before mapping to cards (which also happens in
// EditExercise & PDFPlan), this should probably be moved to back end?
export const PlanModal = ({ isOpen, handleCloseModal, programme, patientDetails }: Props) => {
  const [t] = useTranslation();
  const [downloadPdf, setDownloadPdf] = useState(false);
  const {
    context: { userLanguagePreference },
  } = useLanguagePreference();

  if (downloadPdf) {
    return (
      <DownloadPdfModal
        isOpen={downloadPdf}
        handleCloseModal={() => setDownloadPdf(false)}
        patientDetails={patientDetails}
        programme={programme}
      />
    );
  }
  return (
    <BaseModal isOpen={isOpen} handleCloseModal={handleCloseModal} ariaHideApp={true} title="">
      <div className="plan-modal">
        <h2 className="tab-content__heading mb-4">
          {html(t('forms.patient.tab-headings.plan-activity'))}
        </h2>
        <div className="plan-modal__summary mb-4">
          <p className="plan-activity__card__date">
            {getTimeFromNow(programme.publishedOnUtc, userLanguagePreference)} -{' '}
            {getFriendlyLocalLongDate(programme.publishedOnUtc, userLanguagePreference)}
          </p>
          <p>
            {t('forms.patient.plan-activity.revision-name')} {programme.publishedByUserName}
          </p>
        </div>
        <div className="plan-modal__exercise-list">
          {programme.exercises &&
            programme.exercises[0] &&
            programme.exercises
              .sort(function (a, b) {
                var sequenceA = a.sequence,
                  sequenceB = b.sequence;
                return sequenceA - sequenceB;
              })
              .map((exercise) => (
                <ExerciseCard
                  onClick={() => {}}
                  programmeId={programme.programmeId}
                  key={exercise.programmeExerciseId}
                  id={exercise.baseExerciseId}
                  title={exercise.title}
                  img={exercise.urlImage ? exercise.urlImage : PlaceholderImg}
                  description={exercise.shortDescription}
                  isEditingPlan={false}
                  additionalClassName="plan-modal__exercise-card"
                />
              ))}
        </div>
        <div className="buttons mt-4 plan-modal__button-bar">
          <Button
            onClick={() => handleCloseModal()}
            type="button"
            translationKey="forms.patient.token-modal.close-button"
            variant="filled"
            colour="gray"
            additionalClassName="mr-3 py-2 px-2 is-140-wide"
          ></Button>
          <Button
            onClick={() => {
              setDownloadPdf(true);
            }}
            type="button"
            translationKey="forms.patient.programme-actions.export-plan"
            variant="outlined"
            colour="black"
            additionalClassName="py-2 px-2 is-140-wide"
          ></Button>
        </div>
      </div>
    </BaseModal>
  );
};
