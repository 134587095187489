import React from 'react';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { ProgrammeDetails } from '../../types/ExerciseDetails';
import logo from '../../images/logo-flexterity.png';
import { getFriendlyLocalShortDate } from '../../helpers/dateFormatHelper';
import { useTranslation } from 'react-i18next';
import { PatientResponse } from '../../types/responses';
import { styles } from './PdfPlanStyles.js';
import fonts from './fonts/fonts';

interface PdfPlanProps {
  programme: ProgrammeDetails;
  patientDetails: PatientResponse;
  userLanguagePreference: string | null;
}

// TODO ticket P0936-539 raised to look at the sorting of exercises before mapping to cards (which also happens in
// EditExercise & PlanModal), this should probably be moved to back end?
export const PdfDocument = ({
  programme,
  patientDetails,
  userLanguagePreference,
}: PdfPlanProps) => {
  const { t } = useTranslation();
  const nowDate = new Date();

  // There is an issue with @react-pdf/renderer v3 when using non-default fonts
  // so we are using v2 and also specifiying the fontkit etc versions in package.json
  // P0936-606 created to address package upgrades
  // Custom fonts needed to support non-latin languages.
  const getFont = (lang: string) => {
    return fonts[lang] || fonts['default'];
  };
  const font = getFont(userLanguagePreference || 'default');

  Font.register(font);
  styles.page.fontFamily = font.family;

  const hasFooterDisclaimer = t('pdfPlan.footer-disclaimer-line-1') !== '';

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.banner}>
          <Image src={logo} style={styles.image}></Image>
          <Text style={styles.bannerDate}>
            {t('pdfPlan.printed-on')}{' '}
            {getFriendlyLocalShortDate(nowDate.toISOString(), userLanguagePreference)}
          </Text>
        </View>
        <View style={styles.detailsCol}>
          <Text style={styles.publishedDate}>
            {t('pdfPlan.publish-date')}{' '}
            {getFriendlyLocalShortDate(programme.publishedOnUtc, userLanguagePreference)}
          </Text>
          {programme.reviewDateUtc && !programme.reviewDateUtc.includes('0001') && (
            <Text style={styles.reviewDate}>
              {t('pdfPlan.review-date')}{' '}
              {getFriendlyLocalShortDate(programme.reviewDateUtc, userLanguagePreference)}
            </Text>
          )}
        </View>
        <Text>
          {patientDetails.name} ({patientDetails.age} {t('pdfPlan.patient-age')})
        </Text>
        <Text style={styles.heading}>{t('pdfPlan.exercises')}</Text>
        {programme.exercises
          .sort(function (a, b) {
            var sequenceA = a.sequence,
              sequenceB = b.sequence;
            return sequenceA - sequenceB;
          })
          .map((ex) => (
            <View style={styles.exercise} key={ex.programmeExerciseId}>
              <Text style={styles.bodyText}>{ex.title}</Text>
              <Text style={styles.stepText}>{ex.shortDescription}</Text>
            </View>
          ))}
        <Text style={styles.heading}>{t('pdfPlan.notes')}</Text>
        <View fixed style={styles.footer}>
          <Text style={styles.footerText}>&copy; {nowDate.getFullYear()} Flexterity</Text>
          {hasFooterDisclaimer && (
            <>
              <Text style={styles.footerText}>{t('footer.job-code')}</Text>
              <Text style={styles.footerText}>{t('pdfPlan.footer-disclaimer-line-1')}</Text>
              <Text style={styles.footerText}>{t('pdfPlan.footer-disclaimer-line-2')}</Text>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};
