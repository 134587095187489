import React from 'react';
import BackArrow from '../../images/back-arrow.svg';
import { Button } from '../FormComponents/Buttons/Button';
import './Header.scss';

export interface HeaderProps {
  title: string;
  backButtonText?: string;
  backButtonOnClick?: () => void;
}

const Header = ({ title, backButtonText, backButtonOnClick }: HeaderProps) => {
  return (
    <div className="header">
      <div className="header__container">
        <div className="page-title is-active">
          <h1>{title}</h1>
        </div>
        {backButtonText && backButtonOnClick && (
          <div className="page-title back-button">
            <Button
              onClick={backButtonOnClick}
              translationKey={backButtonText}
              variant="outlined"
              colour="gray"
              type="button"
              icon={BackArrow}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
