import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutate } from 'restful-react';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { Button } from '../../FormComponents/Buttons/Button';
import FileIcon from '../../../images/icon-file--black.svg';
import './CreatePlan.scss';

type NewProgrammeResponse = {
  response: string;
  statusCode: number;
  patientId: string;
  programmeId: string;
  token: string;
  archivedOnUtc: Date;
  publishedOnUtc: Date;
  reviewDateUtc: Date;
  updatedUtc: Date;
  createdUtc: Date;
};

interface CreatePlanProps {
  patientId: string;
  refetchPatientData: () => void;
}

export const CreatePlan = ({ patientId, refetchPatientData }: CreatePlanProps) => {
  const { t } = useTranslation();

  const onSuccess = (body: unknown, data: unknown) => {
    refetchPatientData();
  };

  const {
    mutate: createPlan,
    loading: awaitingCreatePlanApi,
    error: errorPostingPatientProgramme,
  } = useMutate({
    verb: 'POST',
    path: `/patient/${patientId}/programme`,
    onMutate: onSuccess,
  });

  if (errorPostingPatientProgramme) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <div>
      <p className="create-plan__message">{t('forms.patient.programme-actions.no-plan')}</p>
      <Button
        disabled={awaitingCreatePlanApi}
        type="button"
        translationKey="forms.patient.programme-actions.create-new"
        variant="filled"
        colour="orange"
        onClick={() => createPlan(patientId)}
        additionalClassName="mt-6 is-align-self-flex-start"
        icon={FileIcon}
      ></Button>
    </div>
  );
};
