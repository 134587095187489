import React from 'react';
import { ValidationError } from '../ValidationError/ValidationError';
import './TextInput.scss';
import { FieldError } from 'react-hook-form';

type Props = {
  label?: string;
  placeholder: string;
  value: string;
  onChange: (data: string) => void;
  errorType: FieldError | undefined;
  errorMessageKey: string;
  readOnly?: boolean;
  required?: boolean;
  validationPosition?: 'before' | 'after';
};

export const TextInput = ({
  label,
  placeholder,
  value,
  onChange,
  errorType,
  errorMessageKey,
  readOnly,
  required,
  validationPosition = 'before',
}: Props) => {
  return (
    <>
      {validationPosition === 'before' ? (
        <ValidationError errorType={errorType} errorMessageKey={errorMessageKey} />
      ) : null}
      {label && <label className="label">{label}</label>}
      <input
        className={(errorType && 'is-danger ') + ' input input-text'}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
        required={required}
      />
      {validationPosition === 'after' ? (
        <ValidationError errorType={errorType} errorMessageKey={errorMessageKey} />
      ) : null}
    </>
  );
};
