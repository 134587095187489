import React from 'react';
import { BaseModal } from '../BaseModal/BaseModal';
import './DeletePatientModal.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../FormComponents/Buttons/Button';
import { useMutate } from 'restful-react';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import html from '../../../tools/html';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  patientId: string;
};

export const DeletePatientModal = ({ isOpen, handleCloseModal, patientId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSuccess = (res: unknown) => {
    navigate('/home');
    return res;
  };

  const {
    mutate: deletePatient,
    loading: awaitingDeleteApiCall,
    error: errorDeletingPatient,
  } = useMutate({
    verb: 'DELETE',
    path: `/patient`,
    onMutate: onSuccess,
  });

  const handleCancelDelete = () => {
    handleCloseModal();
  };

  const handleConfirmDelete = () => {
    deletePatient(patientId);
  };

  if (errorDeletingPatient) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <BaseModal isOpen={isOpen} handleCloseModal={handleCloseModal} ariaHideApp={true} title="">
      <div className="ReactModal__Content__Title delete-patient-modal">
        <h2 className="delete-patient-modal__title">
          {t(['forms.patient.delete-patient-modal.title'])}
        </h2>
        <p className="delete-patient-modal__instructions">
          {t(['forms.patient.delete-patient-modal.instructions'])}
        </p>
        <p className="delete-patient-modal__warning">
          {html(t(['forms.patient.delete-patient-modal.warning']))}
        </p>
        <ul className="delete-patient-modal__additional-warnings">
          {html(t(['forms.patient.delete-patient-modal.additional-warnings']))}
        </ul>
        <div className="delete-patient-modal__button-row">
          <Button
            type="button"
            translationKey="forms.patient.cancel"
            variant="filled"
            colour="gray"
            onClick={handleCancelDelete}
            additionalClassName="mr-3 py-2 px-5"
          ></Button>
          <Button
            disabled={awaitingDeleteApiCall}
            type="submit"
            translationKey="forms.patient.confirm"
            variant="outlined"
            colour="black"
            onClick={handleConfirmDelete}
            additionalClassName="py-2 px-5"
          ></Button>
        </div>
      </div>
    </BaseModal>
  );
};
