import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/FormComponents/Buttons/Button';
import { TextInput } from '../../../components/FormComponents/TextInput/TextInput';
import InviteToken from '../../../components/InviteToken/InviteToken';
import { PatientDetails, UpdatedPatientDetails } from '../../../types/PatientDetails';
import { PatientResponse } from '../../../types/responses';
import './TabContent.scss';
import html from '../../../tools/html';

interface PatientDetailProps {
  patientDetails: PatientResponse | null;
  updatePatient: (details: UpdatedPatientDetails) => void;
  saveNewPatient: (details: PatientDetails) => void;
  isLoadingMutation: boolean;
  handleOpenDeletePatientModal: (isOpen: boolean) => void;
  setIsTokenModalOpen: (isOpen: boolean) => void;
  hasProgramme: boolean;
  hasActiveProgramme: boolean;
}

export const PatientProfile = ({
  patientDetails,
  updatePatient,
  saveNewPatient,
  isLoadingMutation,
  handleOpenDeletePatientModal,
  setIsTokenModalOpen,
  hasActiveProgramme,
  hasProgramme,
}: PatientDetailProps) => {
  const { t } = useTranslation();

  const defaultValues: PatientDetails = {
    name: patientDetails?.name || '',
    age: patientDetails?.age || '',
    notes: patientDetails?.notes || '',
  };

  const { control, handleSubmit, formState } = useForm<PatientDetails>({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = (data: PatientDetails) => {
    const newPatientDetails: PatientDetails = {
      name: data.name,
      age: data.age,
      notes: '',
    };
    if (patientDetails?.id) {
      updatePatient({ ...newPatientDetails, patientId: patientDetails.id });
    } else {
      saveNewPatient(newPatientDetails);
    }
  };

  return (
    <div className="columns">
      <div className="column is-7">
        <h2 className="tab-content__heading">{html(t('forms.patient.tab-headings.profile'))}</h2>
        <form className="is-flex is-flex-direction-column" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <TextInput
                required
                placeholder={t(['forms.placeholder'])}
                label={t(['forms.patient.name'])}
                value={field.value}
                onChange={field.onChange}
                errorType={formState.errors.name}
                errorMessageKey="error"
              />
            )}
          />
          <Controller
            name="age"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <TextInput
                required
                placeholder={t(['forms.placeholder'])}
                label={t(['forms.patient.age'])}
                value={field.value}
                onChange={field.onChange}
                errorType={formState.errors.name}
                errorMessageKey="error"
              />
            )}
          />
          <div className="buttons mt-4 is-align-self-flex-end">
            <Button
              disabled={!formState.isValid}
              type="button"
              translationKey="forms.patient.delete"
              variant="filled"
              colour="gray"
              onClick={() => handleOpenDeletePatientModal(true)}
              additionalClassName="mr-3 py-2 px-5"
            ></Button>
            <Button
              disabled={!formState.isValid || isLoadingMutation}
              type="submit"
              translationKey="forms.patient.save"
              variant="outlined"
              colour="black"
              additionalClassName="py-2 px-5"
            ></Button>
          </div>
        </form>
        {patientDetails && hasProgramme && (
          <InviteToken
            onClick={() => setIsTokenModalOpen(true)}
            isActive={hasActiveProgramme}
            token={patientDetails.token}
          />
        )}
      </div>
    </div>
  );
};
