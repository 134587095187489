import * as React from 'react';
import './Navbar.scss';
import env from '../../Env';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '../FormComponents/Buttons/Button';
import Logo from '../../images/logo-flexterity.svg';
import Select from 'react-select';
import { LanguageSelectOption, useLanguagePreference } from '../../hooks/useLanguagePreference';

const languagePickerCustomStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#dbdcdd',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
  }),
};

const Navbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    context: { languageOptions, userLanguagePreference },
    changeLanguageHandler,
  } = useLanguagePreference();
  const { logout, isAuthenticated } = useAuth0();

  // TODO this should be able to be typed LanguageSelectOption | null per similar handler for React Select onChange
  // in LanguagePicker but have TS error which I am currently not managing to resolve
  const handleLanguageChange = (option: any) => {
    if (!option) {
      return;
    }
    changeLanguageHandler(option.value, true);
  };

  const loadHomePage = () => {
    navigate('/home');
  };

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        {isAuthenticated && (
          <div className="navbar-language">
            {languageOptions && (
              <Select
                options={languageOptions}
                onChange={handleLanguageChange}
                value={
                  userLanguagePreference
                    ? languageOptions.find(
                        (i: LanguageSelectOption) => i.value === userLanguagePreference,
                      )
                    : ''
                }
                styles={languagePickerCustomStyles}
                className={'navbar-language-item'}
              />
            )}
          </div>
        )}

        <div className="navbar-menu">
          <div className="navbar-start" onClick={loadHomePage}>
            <img src={Logo} alt="Flexterity" />
          </div>

          <div className="navbar-end">
            {isAuthenticated && userLanguagePreference && (
              <>
                <NavLink
                  to="/home"
                  className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')}
                >
                  {t('navbar.clients')}
                </NavLink>
                <NavLink
                  to="/exerciseslist"
                  className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')}
                >
                  {t('navbar.exercises')}
                </NavLink>
                <NavLink
                  to="/my-details"
                  className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')}
                >
                  {t('navbar.my-details')}
                </NavLink>
                <NavLink
                  to="/help"
                  className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')}
                >
                  {t('navbar.help')}
                </NavLink>
              </>
            )}
            {isAuthenticated && (
              <Button
                translationKey="navbar.logout"
                type="button"
                variant="outlined"
                colour="black"
                onClick={() => logout({ returnTo: env.authRedirect })}
                additionalClassName="is-small"
              />
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
