import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';
import html from '../../tools/html';

interface FooterProps {
  jobCode: string;
}

const Footer = ({ jobCode }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer className="footer" aria-label="main footer">
      <div className="footer-bar">
        <div className="footer-start">
          <div className="footer-start__top">{html(t('footer.copyright'))}</div>

          <div className="footer-start__bottom">
            <a href="/terms-of-use">{t('footer.terms-of-use')}</a>
            <a href="/privacy-policy">{t('footer.privacy-policy')}</a>
            <br />
            <br />
            <p>{jobCode}</p>
          </div>
        </div>
        <div className="footer-end">{html(t('footer.links'))}</div>
      </div>
    </footer>
  );
};

export default Footer;
