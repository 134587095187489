import * as React from 'react';
import DOMPurify from 'dompurify';

interface IProps {
  htmlString: string;
}

const TranslationHtml = ({ htmlString }: IProps) => {
  return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlString) }}></span>;
};

export default TranslationHtml;
