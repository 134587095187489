import React from 'react';
import { BaseModal } from '../BaseModal/BaseModal';
import './SuccessModal.scss';
import Check from '../../../images/icon-check.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  translationKey: string;
};

export const SuccessModal = ({ isOpen, handleCloseModal, translationKey }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} handleCloseModal={handleCloseModal} ariaHideApp={true} title="">
      <div className="publish-check">
        <img src={Check} alt="Success" />
      </div>
      <div className="ReactModal__Content__Title">
        <h2>{t([translationKey])}</h2>
      </div>
    </BaseModal>
  );
};
